<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full">
        <div>
            <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
                <ol class="list-reset flex text-gray-700 dark:text-gray-300">
                    <li>
                        <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>

                    </li>
                    <li>
                        <span class="mx-2">></span>
                    </li>
                    <li class="text-gray-500 dark:text-gray-400">HR Processes</li>
                </ol>
            </nav>
            <div class="fixed right-0 xl:right-5 bottom-0">
                <router-link to="/HRFormUploader" class="relative group">
                    <i class="fas fa-edit text-xl text-company-color-1"></i>
                    <span class="absolute bottom-full left-1/2 transform -translate-x-1/2 mb-1 hidden group-hover:block bg-gray-800 text-white text-xs rounded py-1 px-2">
                        Edit
                    </span>
                </router-link>
            </div>
            <div class="sticky top-0 shadow-lg bg-white dark:bg-gray-800 p-4 mx-4 mt-4 z-10 flex flex-col md:flex-row justify-between items-center rounded-lg">
                <div class="text-center md:text-left">
                    <h4 class="text-2xl md:text-xl lg:text-2xl font-semibold">HR Processes</h4>
                    <p class="text-sm md:text-base lg:text-md">Please select the processes that you wish to view</p>
                </div>
                <div class="relative w-full max-w-md mt-4 md:mt-0">
                    <input
                        v-model="searchQuery"
                        type="text"
                        placeholder="Search for processes..."
                        class="w-full p-2 pl-10 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
                    />
                    <i class="fa-solid fa-magnifying-glass absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
                </div>
            </div>
            <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-3 2xl:grid-cols-5 gap-4 p-4">
                <div
                    v-for="template in filteredTemplates"
                    :key="template.id"
                    class="bg-white dark:bg-gray-800 rounded-md shadow-md flex flex-col items-center min-h-72"
                >
                    <div class="front-page text-center flex flex-col justify-between h-full w-full">
                        <div>
                            <img :src="template.imageSrc" alt="Form Image" class="w-full h-64 mb-4 rounded-t-lg" />
                            <p class="mb-4">{{ template.title }}</p>
                        </div>
                        <div class="w-full mt-auto flex flex-col 2xl:flex-row 2xl:justify-center space-y-2 2xl:space-y-0 2xl:space-x-2 p-4">
                            <router-link
                                :to="template.link"
                                class="text-sm lg:text-base bg-blue-500 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-2/3 flex items-center justify-center px-1"
                            >
                                <i class="fa-solid fa-eye mr-1"></i>
                                View
                            </router-link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import EmployeeExitImage from '@/assets/images/HRProcesses/employee-exit.jpg';
import HiringImage from '@/assets/images/HRProcesses/hiring.jpg';
import OnboardingImage from '@/assets/images/HRProcesses/onboarding.jpg';
import PayrollImage from '@/assets/images/HRProcesses/payroll.jpg';
import PerformanceManagementImage from '@/assets/images/HRProcesses/performance-management.jpg';
import ProfileUpdateImage from '@/assets/images/HRProcesses/profile-update.jpg';
import TalentAcquisitionImage from '@/assets/images/HRProcesses/talent-acquisation.jpg';

export default {
    name: 'HRProcesses',
    data() {
        return {
            searchQuery: '',
            templates: [
                {
                    id: 0,
                    title: 'Employee Exit',
                    link: '',
                    imageSrc: EmployeeExitImage,
                },
                {
                    id: 1,
                    title: 'Hiring',
                    link: '',
                    imageSrc: HiringImage,
                },
                {
                    id: 2,
                    title: 'Onboarding',
                    link: '',
                    imageSrc: OnboardingImage,
                },
                {
                    id: 3,
                    title: 'Payroll',
                    link: '',
                    imageSrc: PayrollImage,
                },
                {
                    id: 4,
                    title: 'Performance Management',
                    link: '',
                    imageSrc: PerformanceManagementImage,
                },
                {
                    id: 5,
                    title: 'Profile Update',
                    link: '',
                    imageSrc: ProfileUpdateImage,
                },
                {
                    id: 6,
                    title: 'Talent Acquisition',
                    link: '',
                    imageSrc: TalentAcquisitionImage,
                },
            ],
        };
    },
    computed: {
        filteredTemplates() {
            return this.templates.filter(template =>
                template.title.toLowerCase().includes(this.searchQuery.toLowerCase())
            );
        },
    },
    methods: {
        async downloadDocument(firebasePath) {
            const storage = getStorage();
            const fileRef = storageRef(storage, firebasePath);
            const url = await getDownloadURL(fileRef);
            
            const a = document.createElement('a');
            a.href = url;
            a.download = firebasePath.split('/').pop();
            document.body.appendChild(a);
            a.click();
            document.body.removeChild(a);
        }
    }
};
</script>

<style scoped>
</style>
