<template>
    <nav class= "px-4 pt-2 mb-1 mx-4 text-xs lg:text-md h-6">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
                <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/FinancePolicies" class="text-company-color-1 hover:text-company-color-2">Finance Policies</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Expense Reimbursement</li>
        </ol>
    </nav>
    <PDFViewer documentId="GM61pCz5TYqPhncMyN13" />
</template>

<script>
import PDFViewer from '@/components/PdfViewer.vue';

export default {
name: "ExpenseReimbursement",
components: {
  PDFViewer
}
};
</script>

<style scoped>
</style>
  