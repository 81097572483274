// src/firebase/init.js
import { initializeApp } from "firebase/app";
import { getAnalytics } from "firebase/analytics";
import { getFirestore } from "firebase/firestore";
import { getStorage } from "firebase/storage";

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyC5T1dUYKCBQTD-ytKd1ZvcsTt3RbGpcro",
  authDomain: "intranetv2-c53c8.firebaseapp.com",
  projectId: "intranetv2-c53c8",
  storageBucket: "intranetv2-c53c8.appspot.com",
  messagingSenderId: "342024674830",
  appId: "1:342024674830:web:4ef1c9d8a424b68a06665f"
};

// Initialize Firebase
export const firebaseApp = initializeApp(firebaseConfig);
const analytics = getAnalytics(firebaseApp);

// Initialize Firestore and Storage services
const db = getFirestore(firebaseApp);
const storage = getStorage(firebaseApp);

export { db, storage, analytics };
