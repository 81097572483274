<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
      <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/JobDescriptionLibrary" class="text-company-color-1 hover:text-company-color-2">Job Description Library</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Add New Job Description</li>
        </ol>
      </nav>
      <div class="flex flex-col items-center h-full p-4">
        <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full">
          <h2 class="font-bold text-2xl md:text-3xl mb-6">Add New Job Description</h2>
          <form @submit.prevent="onSubmit" enctype="multipart/form-data" class="space-y-4">
            <div>
              <label for="title" class="block text-gray-700 dark:text-gray-300 mb-2">Job Title</label>
              <input
                type="text"
                v-model="title"
                id="title"
                required
                placeholder="Enter job title"
                class="mt-1 w-full rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2"
              />
            </div>
            <div>
              <label for="docName" class="block text-gray-700 dark:text-gray-300 mb-2">Document Name</label>
              <input
                type="text"
                v-model="docName"
                id="docName"
                required
                placeholder="Enter document name (e.g., JD-Digital-Marketer.docx)"
                class="mt-1 w-full rounded-lg border-2 border-gray-300 dark:border-gray-600 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2"
              />
            </div>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2">Upload Document</label>
              <div
                @click="triggerFileInput"
                @dragover.prevent
                @dragenter.prevent
                @drop="onDrop"
                class="border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700"
              >
                <input type="file" ref="file" @change="onSelect" class="hidden" />
                <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                  <i class="fa-solid fa-download"></i>
                </div>
                <p v-if="selectedFile" class="mt-2 text-gray-600 dark:text-gray-300">{{ selectedFile.name }}</p>
                <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop files here to upload</p>
              </div>
            </div>
            <div v-if="message" class="my-4">
              <h5 class="text-gray-700 dark:text-gray-300">{{ message }}</h5>
            </div>
            <div>
              <button type="submit" class="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-400 focus:outline-none focus:shadow-outline">
                Submit
              </button>
            </div>
          </form>
          <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
            <img :src="loadingGif" alt="Loading..." class="h-28" />
            <p class="text-white ml-3">Loading...</p>
          </div>
        </div>
      </div>
    </div>
</template>

<script>
import { ref as storageRef, uploadBytes, getDownloadURL } from "firebase/storage";
import { addDoc, collection } from "firebase/firestore";
import { storage, db } from "@/firebase/init";
import loadingGif from "@/assets/images/loading.gif";
import { useToast } from "vue-toastification";

export default {
  name: "AddJobDescription",
  data() {
    return {
    title: "",
    docName: "",
    selectedFile: null,
    message: "",
    loading: false,
    loadingGif
    };
  },
  methods: {
      triggerFileInput() {
        this.$refs.file.click();
      },
      onSelect() {
        const file = this.$refs.file.files[0];
        this.validateFile(file);
      },
      onDrop(event) {
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        this.validateFile(file);
      },
      validateFile(file) {
        const allowedTypes = [
          "application/msword",
          "application/vnd.openxmlformats-officedocument.wordprocessingml.document"
        ];
      if (!allowedTypes.includes(file.type)) {
        this.message = "Only .doc and .docx files are allowed!";
        this.selectedFile = null;
      } else if (file.size > 1000000) {
        this.message = "Too large, max size allowed is 1000KB";
        this.selectedFile = null;
      } else {
        this.selectedFile = file;
        this.message = "";
      }
      },
      async onSubmit() {
        if (!this.selectedFile) {
            this.message = "Please select a valid file";
            return;
        }

        const toast = useToast();
        const fileName = `Job Descriptions/${this.docName}`;
        const fileRef = storageRef(storage, fileName);

        this.loading = true;

        try {
            await uploadBytes(fileRef, this.selectedFile);

            await addDoc(collection(db, "Job_Descriptions_Titles"), {
            title: this.title,
            docName: this.docName,
            });

            toast.success("Job description added successfully!");
            this.resetForm();
        } catch (error) {
            console.error("Error uploading file:", error);
            toast.error("Upload failed!");
        } finally {
            this.loading = false;
        }
      },
      resetForm() {
        this.title = "";
        this.docName = "";
        this.selectedFile = null;
        this.message = "";
      }
  }
};
</script>

<style scoped>
</style>
  