import * as Sentry from "@sentry/vue";

export default function initSentry(app, router) {
    const dsn = import.meta.env.VITE_SENTRY_DSN ?? ''
    if (!dsn) return

    Sentry.init({
        app,
        dsn,
        integrations: [
            // Sentry.browserTracingIntegration({ router }),
            // Sentry.replayIntegration(),
        ],
        // Tracing
        tracesSampleRate: 0, //  Capture 100% of the transactions
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: ["localhost", /^https:\/\/intranetv2\.vilor\.com/],
        // Session Replay
        replaysSessionSampleRate: 0, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
        replaysOnErrorSampleRate: 0.5, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
}