import './assets/tailwind.css'

import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import Toast from "vue-toastification";
import "vue-toastification/dist/index.css";
import initSentry from './sentry';

const app = createApp(App)
initSentry(app, router)

app.use(router)
app.use(Toast, {
    position: 'bottom-right',
    toastClassName: 'custom-toast',
    bodyClassName: 'custom-toast-body',
});
app.mount('#app')