<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2">
      <nav class= "pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
                <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/HRTemplates" class="text-company-color-1 hover:text-company-color-2">HR Templates</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Employment Contract Letter - Marketing(Fixed Term)</li>
        </ol>
      </nav>
      <div class="flex flex-col 2xl:flex-row justify-between p-4">
        <div class="w-full 2xl:w-7/12 mx-auto lg:mr-4">
          <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Employment Contract Letter - Marketing(Fixed Term)</h2>
          </div>
          <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
          <div class="bg-white dark:bg-gray-800 rounded-lg">
            <form @submit.prevent="generateDocument" class="space-y-4">
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:p  x-12 border-b-2 border-gray-200 dark:border-gray-600">Candidate Information</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 p-4">
                  <div class="select-container">
                    <label for="candidatetitle" class="block">Courtesy Title:</label>
                    <select v-model="formData.candidatetitle" id="candidatetitle" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="candidatelastname" class="block">Last Name:</label>
                    <input type="text" v-model="formData.candidatelastname" id="candidatelastname" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="candidatename" class="block">Full Name:</label>
                    <input type="text" v-model="formData.candidatename" id="candidatename" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="candidateic" class="block">IC Number:</label>
                    <input type="text" v-model="formData.candidateic" id="candidateic" required placeholder="XXXXXX-XX-XXXX" pattern="[0-9]{6}-[0-9]{2}-[0-9]{4}" title="Enter your IC number in the format: XXXXXX-XX-XXXX" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>
  
                <fieldset class="border-2 border-gray-200 dark:border-gray-600 mt-4 p-4 rounded">
                  <legend class="font-semibold">Address</legend>
                  <div class="space-y-4 mt-2">
                    <div>
                      <label for="addressline1" class="block">Address Line 1:</label>
                      <input type="text" v-model="formData.addressline1" id="addressline1" required placeholder="House No., Building, Street" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                    <div>
                      <label for="addressline2" class="block">Address Line 2:</label>
                      <input type="text" v-model="formData.addressline2" id="addressline2" required placeholder="Postcode, Area" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                    <div>
                      <label for="addressline3" class="block">Address Line 3:</label>
                      <input type="text" v-model="formData.addressline3" id="addressline3" required placeholder="State" value="Pulau Pinang" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                  </div>
                </fieldset>
                
                <div class="grid md:grid-cols-2 gap-4 mt-4 p-4">
                  <div>
                    <label for="candidateposition" class="block">Position:</label>
                    <input type="text" v-model="formData.candidateposition" id="candidateposition" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div class="select-container">
                    <label for="jobgrade" class="block">Job Grade:</label>
                    <select v-model="formData.jobgrade" id="jobgrade" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="(A3)">A3</option>
                      <option value="(B1)">B1</option>
                      <option value="(B2)">B2</option>
                      <option value="(B3)">B3</option>
                      <option value="(C1)">C1</option> 
                      <option value="(C2)">C2</option>
                      <option value="(C3)">C3</option>
                      <option value="(D1)">D1</option>
                      <option value="(D2)">D2</option>
                      <option value="(D3)">D3</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="salary" class="block">Salary (RM):</label>
                    <input type="text" v-model="formData.salary" id="salary" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="supplementaryallowance" class="block">Supplementary Allowance (RM):</label>
                    <input type="text" v-model="formData.supplementaryallowance" id="supplementaryallowance" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="candidatestartdate" class="block">Start Date:</label>
                    <input type="text" v-model="formData.candidatestartdate" id="candidatestartdate" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                  </div>
                  <div class="select-container">
                    <label for="fixedtermperiod" class="block">Fixed-Term Period:</label>
                    <select v-model="formData.fixedtermperiod" id="fixedtermperiod" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="three (3)">3 months</option>
                      <option value="four (4)">4 months</option>
                      <option value="five (5)">5 months</option>
                      <option value="six (6)">6 months</option>
                      <option value="nine (9)">9 months</option>
                      <option value="twelve (12)">12 months</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div class="select-container">
                    <label for="terminationnoticeperiod" class="block">Termination Notice Period (Weeks):</label>
                    <select v-model="formData.terminationnoticeperiod" id="terminationnoticeperiod" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="one (1) week's">1</option>
                      <option value="two (2) weeks'">2</option>
                      <option value="three (3) weeks'">3</option>
                      <option value="four (4) weeks'">4</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                </div>
                <div class="p-4 mt-4">
                  <div class="grid md:grid-cols-2 gap-4 mt-4">
                    <div>
                      <label for="dateofsignature" class="block">Date of Signature:</label>
                      <input type="text" v-model="formData.dateofsignature" id="dateofsignature" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                    </div>
                    <div>
                      <label for="datetoday" class="block">Date of this Letter:</label>
                      <input type="text" v-model="formData.datetoday" id="datetoday" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                    </div>
                  </div>
                </div>
              </fieldset>
  
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Company Information</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 mt-2 p-4">
                  <div>
                    <label for="reportingmanagerjobtitle" class="block">Reporting Manager Job Title:</label>
                    <input type="text" v-model="formData.reportingmanagerjobtitle" id="reportingmanagerjobtitle" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div class="select-container">
                    <label for="companyname" class="block">Company Name:</label>
                    <select v-model="formData.companyname" id="companyname" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Vilor Berhad">Vilor Berhad</option>
                      <option value="Vilor Partners Berhad">Vilor Partners Berhad</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="companynamehereinreferredtoas" class="block">Company Name hereinafter referred to as:</label>
                    <input type="text" v-model="formData.companynamehereinreferredtoas" id="companynamehereinreferredtoas" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="signingname" class="block">Signing Name:</label>
                    <input type="text" v-model="formData.signingname" id="signingname" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="signingposition" class="block">Signing Position:</label>
                    <input type="text" v-model="formData.signingposition" id="signingposition" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>
              </fieldset>
  
              <div class="flex justify-between items-center p-8 pt-0">
                <div>
                  <button type="submit" class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto">Download Document</button>
                  <input type="reset" class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto" value="Reset">
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="w-full 2xl:w-5/12 mx-auto flex 2xl:justify-end 2xl:mt-20 ">
          <div class="max-w-md h-fit bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 rounded-lg p-8 mt-4 2xl:sticky 2xl:top-10">
            <h3 class="font-bold text-3xl">Note:</h3>
            <ul class="list-disc pl-4 mt-4">
              <li class="pb-3">The required information shall be completed to generate the <span class="text-company-color-1"><b>Employment Contract Letter-Marketing(Fixed Term)</b></span> in Microsoft Word.</li>
              <li class="pb-3">Please review the document before printing it out for signature.</li>
              <li class="pb-3">The signed copy shall be kept in <span class="text-company-color-1"><u><a href="https://vilor2016.sharepoint.com/sites/VilorBerhad/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FVilorBerhad%2FShared%20Documents%2FHR&viewid=cd7c884f%2Da1f7%2D45d2%2D8c75%2D7dadd4531fe9" target="_blank">Vilor's SharePoint</a></u></span> for record retention.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </template>
  
  <script>
  import { ref, onMounted } from 'vue';
  import PizZip from 'pizzip';
  import Docxtemplater from 'docxtemplater';
  import { saveAs } from 'file-saver';
  import flatpickr from 'flatpickr';
  import 'flatpickr/dist/flatpickr.min.css';
  import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
  import { useToast } from 'vue-toastification';
  import 'vue-toastification/dist/index.css';
  
  export default {
    setup() {
      const formData = ref({
        candidatetitle: 'Mr',
        candidatename: '',
        candidatelastname: '',
        candidateic: '',
        addressline1: '',
        addressline2: '',
        addressline3: 'Pulau Pinang',
        candidateposition: '',
        jobgrade: '',
        salary: '',
        supplementaryallowance: '',
        candidatestartdate: '',
        fixedtermperiod: 'three (3)',
        terminationnoticeperiod: 'one (1) week\'s',
        dateofsignature: '',
        datetoday: '',
        reportingmanagerjobtitle: '',
        companyname: 'Vilor Berhad',
        companynamehereinreferredtoas: '',
        signingname: '',
        signingposition: ''
      });

      const today = new Date();
      formData.value.datetoday = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`;
  
      const toast = useToast();
  
      const convertToUppercase = (event) => {
        event.target.value = event.target.value.toUpperCase();
      };
  
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      };
  
      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      };
  
      const formatFullDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = date.getDate();
          const month = date.toLocaleString('en-GB', { month: 'long' });
          const year = date.getFullYear();
          const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
              case 1: return 'st';
              case 2: return 'nd';
              case 3: return 'rd';
              default: return 'th';
            }
          };
          return `${day}${daySuffix(day)} ${month} ${year}`;
        }
        return '';
      };
  
      const formatShortDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = String(date.getDate()).padStart(2, '0');
          const month = date.toLocaleString('en-GB', { month: 'short' });
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        }
        return '';
      };

      const formatDateToDDMMYYYY = (date) => {
        const day = String(date.getDate()).padStart(2, '0');
        const month = String(date.getMonth() + 1).padStart(2, '0'); 
        const year = date.getFullYear();
        return `${day}/${month}/${year}`;
      };

      const calculateEndDate = (startDate, fixedTermPeriod) => {
        if (startDate && fixedTermPeriod) {
          const startTimestamp = new Date(startDate.split('/').reverse().join('/')); 
          const periodMatch = fixedTermPeriod.match(/\d+/);
          const periodValue = periodMatch ? parseInt(periodMatch[0]) : 0;

          const candidateEndTimestamp = new Date(startTimestamp);
          candidateEndTimestamp.setMonth(candidateEndTimestamp.getMonth() + periodValue);

          if (candidateEndTimestamp.getDate() < startTimestamp.getDate()) {
            candidateEndTimestamp.setMonth(candidateEndTimestamp.getMonth() + 1, 0); 
          }

          const endDate = formatDateToDDMMYYYY(candidateEndTimestamp);
          console.log('Start Timestamp:', startTimestamp);
          console.log('Candidate End Timestamp:', candidateEndTimestamp);
          console.log('Start Date:', startDate);
          console.log('extract value:', periodValue);
          console.log('End Date:', endDate);
          return endDate;
        }
        return '';
      };

      const formatNumber = (number) => {
        return parseFloat(number).toLocaleString('en-US', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        });
      };

      const fetchTemplateUrl = async () => {
        const storage = getStorage();
        const templateRef = storageRef(storage, 'HR Templates/Employment-Contract-Letter-Marketing-Fixed-Term-Template.docx');
        return await getDownloadURL(templateRef);
      };
  
      const generateDocument = async () => {
        try {
          toast.info('Download started. Please wait...');
          const templateUrl = await fetchTemplateUrl();
          const response = await fetch(templateUrl);
          const templateArrayBuffer = await response.arrayBuffer();
          const zip = new PizZip(templateArrayBuffer);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true
          });
  
          const formattedemployeename = formData.value.candidatename.toUpperCase();
          const formattedStartDate = formatFullDate(formData.value.candidatestartdate);
          const formattedSignatureDate = formatShortDate(formData.value.dateofsignature);
          const dateToday = formatShortDate(formData.value.datetoday);
          const upperCompanyName = formData.value.companyname.toUpperCase();
          const formattedLastName = capitalizeFirstLetter(formData.value.candidatelastname);
          const candidateEndDate = calculateEndDate(formData.value.candidatestartdate, formData.value.fixedtermperiod);
          const formattedEndDate = formatFullDate(candidateEndDate);
          const formattedmonthlysalary = formatNumber(formData.value.salary);
          const formattedsupplementaryallowance = formatNumber(formData.value.supplementaryallowance);

          const dataToSet = {
            ...formData.value,
            employeename: formattedemployeename,
            candidatestartdate: formattedStartDate,
            candidateenddate: formattedEndDate,
            dateofsignature: formattedSignatureDate,
            datetoday: dateToday,
            uppercompanyname: upperCompanyName,
            candidatelastname: formattedLastName,
            salary: formattedmonthlysalary,
            supplementaryallowance: formattedsupplementaryallowance
          };
  
          doc.setData(dataToSet);
          doc.render();
  
          const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          });
  
          saveAs(out, `Employment-Contract-Letter-Marketing(Fixed-Term)_${formData.value.candidatename.toUpperCase()}.docx`);
          toast.success('Document downloaded successfully!');
        } catch (error) {
          toast.error('Error generating document');
          console.error('Error generating document:', error);
        }
      };
  
      onMounted(() => {
        flatpickr('.datepicker', {
          dateFormat: 'd/m/Y',
          static: true
        });
      });
  
      return {
        formData,
        convertToUppercase,
        generateDocument
      };
    }
  };
  </script>
  
<style scoped>
.fields {
  margin-bottom: 10px;
}
.message h5 {
  color: red;
}

  .select-container {
  position: relative;
  display: inline-block;
}

.icon-container {
  position: absolute;
  top: 45px;
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2rem;
}
  </style>
  
