<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li>
          <router-link to="/HRForms" class="text-company-color-1 hover:text-company-color-2">HR Forms</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">New Hire Form</li>
      </ol>
    </nav>
    <div class="flex flex-col 2xl:flex-row justify-between p-4">
      <div class="w-full 2xl:w-7/12 mx-auto lg:mr-4">
        <div class="flex justify-between items-center mb-4">
          <h2 class="font-bold text-2xl md:text-3xl">New Hire Form</h2>
        </div>

        <step-progress :steps="steps" :current-step="currentStep" icon-class="fa fa-check" active-color="gray"
          passive-color="lightgray" :active-thickness="5" :passive-thickness="5" :line-thickness="2" />
        <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
        <div class="bg-white dark:bg-gray-800 rounded-lg">
          <form @submit.prevent="handleSubmit" class="space-y-4" ref="newHireForm">
            <div
              class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">
              Employee Contacts</div>
            <fieldset class="rounded p-2 md:p-8" v-if="!isPreview">
              <div class="grid md:grid-cols-2 gap-4 p-4">
                <div>
                  <label for="employeename" class="block">Full Name:</label>
                  <input type="text" v-model="formData.employeename" id="employeename" @input="convertToUppercase"
                    required
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="employeenumber" class="block">Employee Number:</label>
                  <input type="text" v-model="formData.employeenumber" id="employeenumber" @input="convertToUppercase"
                    required placeholder="<VBXXX>"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="department" class="block">Department:</label>
                  <select v-model="formData.department" id="department" required
                    class="bg-white dark:bg-gray-800 mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    <option value="" disabled selected hidden>Select a Department</option>
                    <option value="Administration">Administration</option>
                    <option value="Finance">Finance</option>
                    <option value="Human Resource">Human Resource</option>
                    <option value="Information Technology">Information Technology</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Sales">Sales</option>
                  </select>
                </div>
                <div>
                  <label for="jobtitle" class="block">Job Title:</label>
                  <input type="text" v-model="formData.jobtitle" id="jobtitle" required
                    placeholder="<As per Offer Letter>"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="employeebank" class="block">Registered Bank:</label>
                  <select v-model="formData.employeebank" id="employeebank" required
                    class="bg-white dark:bg-gray-800 mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    <option value="" disabled selected hidden>Select a Bank</option>
                    <option value="AFFIN BANK">Affin Bank</option>
                    <option value="AGROBANK">Agrobank</option>
                    <option value="AL-RAJHI">Al-Rajhi Bank</option>
                    <option value="ALLIANCE BANK">Alliance Bank</option>
                    <option value="AMBANK">AmBank</option>
                    <option value="BANK ISLAM">Bank Islam</option>
                    <option value="BANK MUAMALAT">Bank Muamalat</option>
                    <option value="BANK RAKYAT">Bank Rakyat</option>
                    <option value="BANK SIMPANAN NASIONAL">Bank Simpanan Nasional</option>
                    <option value="BANK OF AMERICA">Bank of America</option>
                    <option value="BANK OF CHINA">Bank of China</option>
                    <option value="BIGPAY">BigPay</option>
                    <option value="CIMB BANK">CIMB Bank</option>
                    <option value="CHINA CONSTRUCTION BANK">China Construction Bank</option>
                    <option value="DEUTSCHE BANK">Deutsche Bank</option>
                    <option value="HSBC BANK">HSBC Bank</option>
                    <option value="HONG LEONG BANK">Hong Leong Bank</option>
                    <option value="ICBC BANK">ICBC Bank</option>
                    <option value="JP MORGAN BANK">JP Morgan Bank</option>
                    <option value="KUWAIT FINANCE HOUSE">Kuwait Finance House</option>
                    <option value="MBSB BANK">MBSB Bank</option>
                    <option value="MUFG BANK">MUFG Bank</option>
                    <option value="MAYBANK">Maybank</option>
                    <option value="MIZUHO BANK">Mizuho Bank</option>
                    <option value="OCBC BANK">OCBC Bank</option>
                    <option value="PUBLIC BANK">Public Bank</option>
                    <option value="RHB BANK">RHB Bank</option>
                    <option value="STANDARD CHARTERED">Standard Chartered</option>
                    <option value="SUMITOMO MITSUI">Sumitomo Mitsui</option>
                    <option value="UOB BANK">UOB Bank</option>
                  </select>
                </div>
                <div>
                  <label for="employeebankaccountnumber" class="block">Bank Account Number:</label>
                  <input type="text" v-model="formData.employeebankaccountnumber" id="employeebankaccountnumber"
                    required
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="kwspepfnumber" class="block">KWSP/EPF Number:</label>
                  <input type="text" v-model="formData.kwspepfnumber" id="kwspepfnumber" required
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="perkesosocsonumber" class="block">PERKESO/SOCSO Number:</label>
                  <input type="text" v-model="formData.perkesosocsonumber" id="perkesosocsonumber"
                    placeholder="<Optional>"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="incometaxnumber" class="block">Tax Identification Number (TIN):</label>
                  <input type="text" v-model="formData.incometaxnumber" id="incometaxnumber" required
                    placeholder="<Example: IG 10234567090>"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="lhdnbranch" class="block">Cawangan LHDNM:</label>
                  <input type="text" v-model="formData.lhdnbranch" id="lhdnbranch" required
                    placeholder="<Example: Pulau Pinang, Seberang Perai>"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="datetoday" class="block">Date of this Form:</label>
                  <input type="text" v-model="formData.datetoday" id="datetoday" required placeholder="Select Date"
                    class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
              </div>
            </fieldset>
            <div v-else class="overflow-auto">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-700 ">
                <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-700">
                  <tr v-for="(value, key) in formData" :key="key">
                    <td
                      class="px-8 lg:px-12 py-4 whitespace-nowrap text-md font-medium text-gray-900 dark:text-gray-200">
                      {{ fieldLabels[key] || key }}
                    </td>
                    <td class="px-6 py-4 whitespace-nowrap text-md text-gray-500 dark:text-gray-300">
                      {{ value }}
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
            <div class="flex justify-between items-center p-8 pt-0">
              <div>
                <button v-if="!isPreview" @click="previewForm" :disabled="!isFormValid"
                  class="bg-blue-500 text-white px-4 py-2 rounded m-2 w-full md:w-auto"
                  :class="{ 'opacity-50 cursor-not-allowed': !isFormValid }">Preview</button>
                <button v-else type="button" @click="submitForm"
                  class="bg-blue-500 text-white px-4 py-2 rounded m-2 w-full md:w-auto">Submit</button>
                <button v-if="isPreview" @click="editForm"
                  class="bg-gray-500 text-white px-4 py-2 rounded m-2 w-full md:w-auto">Back</button>
                <input v-if="!isPreview" type="reset"
                  class="bg-gray-500 text-white px-4 py-2 rounded m-2 w-full md:w-auto" value="Reset">
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="w-full 2xl:w-5/12 mx-auto flex 2xl:justify-end 2xl:mt-20 ">
        <div
          class="max-w-md h-fit bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 rounded-lg p-8 mt-4 2xl:sticky 2xl:top-10">
          <h3 class="font-bold text-3xl">Note:</h3>
          <ul class="list-disc pl-4 mt-4">
            <li class="pb-3">The required information shall be completed to generate the <span
                class="text-company-color-1"><b>New Hire Excel Spreadsheet </b></span>.</li>
            <li class="pb-3">Please review the form before submitting it so that the form will be sent to the HR
              department via email.</li>
            <li class="pb-3">Please check with the HR department to ensure the form is received.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, computed, onMounted, nextTick } from 'vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import StepProgress from 'vue-step-progress';
import 'vue-step-progress/dist/main.css';
import ExcelJS from 'exceljs';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

import { db } from "@/firebase/init.js";
import { doc, getDoc } from "firebase/firestore";

export default {
  components: {
    'step-progress': StepProgress
  },
  setup() {
    const isPreview = ref(false);
    const currentStep = ref(0);
    const toast = useToast();
    const newHireForm = ref(null);

    const formData = ref({
      employeename: '',
      employeenumber: '',
      department: '',
      jobtitle: '',
      employeebank: '',
      employeebankaccountnumber: '',
      kwspepfnumber: '',
      perkesosocsonumber: '',
      incometaxnumber: '',
      lhdnbranch: '',
      datetoday: ''
    });

    const requiredFields = ['employeename', 'employeenumber', 'department', 'jobtitle', 'employeebank', 'employeebankaccountnumber', 'kwspepfnumber', 'incometaxnumber', 'lhdnbranch', 'datetoday'];

    const fieldLabels = {
      employeename: 'Full Name',
      employeenumber: 'Employee Number',
      department: 'Department',
      jobtitle: 'Job Title',
      employeebank: 'Registered Bank',
      employeebankaccountnumber: 'Bank Account Number',
      kwspepfnumber: 'KWSP/EPF Number',
      perkesosocsonumber: 'PERKESO/SOCSO Number',
      incometaxnumber: 'Tax Identification Number (TIN)',
      lhdnbranch: 'Cawangan LHDNM',
      datetoday: 'Date of this Form'
    };

    const steps = ['Step 1', 'Step 2'];

    const mailConfig = ref(null);

    const fetchMailConfig = async () => {
      try {
        const docRef = doc(db, "configuration", "companyInfo");
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          mailConfig.value = docSnap.data().mailConfig;
        } else {
          console.log("No such document!");
        }
      } catch (error) {
        console.error("Error fetching mailConfig:", error);
      }
    };

    const convertToUppercase = (event) => {
      formData.value.employeename = formData.value.employeename.toUpperCase();
    };

    const previewForm = () => {
      if (isFormValid.value) {
        isPreview.value = true;
        currentStep.value = 1;
      }
    };

    const editForm = () => {
      isPreview.value = false;
      currentStep.value = 0;
    };

    const handleSubmit = () => {
      if (isPreview.value) {
        submitForm();
      } else {
        previewForm();
      }
    };

    const fetchAndEditExcel = async () => {
      const storage = getStorage();
      const excelFileRef = storageRef(storage, 'HR Forms/New_Hire_Form.xlsx');
      const url = await getDownloadURL(excelFileRef);
      const response = await fetch(url);
      const arrayBuffer = await response.arrayBuffer();

      const workbook = new ExcelJS.Workbook();
      await workbook.xlsx.load(arrayBuffer);

      const worksheet = workbook.worksheets[0];

      worksheet.getCell('C6').value = formData.value.employeename;
      worksheet.getCell('C7').value = formData.value.employeenumber;
      worksheet.getCell('C8').value = formData.value.department;
      worksheet.getCell('C9').value = formData.value.jobtitle;
      worksheet.getCell('C11').value = formData.value.employeebank;
      worksheet.getCell('C12').value = formData.value.employeebankaccountnumber;
      worksheet.getCell('C14').value = formData.value.kwspepfnumber;
      worksheet.getCell('C15').value = formData.value.perkesosocsonumber;
      worksheet.getCell('C17').value = formData.value.incometaxnumber;
      worksheet.getCell('C18').value = formData.value.lhdnbranch;
      worksheet.getCell('C20').value = formData.value.datetoday;

      const buffer = await workbook.xlsx.writeBuffer();
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });

      const formDataToSend = new FormData();
      formDataToSend.append('file', blob, `New_Hire_Form_${formData.value.employeenumber}.xlsx`);
      formDataToSend.append('employeename', formData.value.employeename);
      formDataToSend.append('employeenumber', formData.value.employeenumber);
      formDataToSend.append('mailConfig', JSON.stringify(mailConfig.value)); // Passing mailConfig

      await fetch('/php/SendEmail.php', {
        method: 'POST',
        body: formDataToSend,
      });
    };

    const submitForm = async () => {
      try {
        toast.info('Processing your request. Please wait...');
        if (!mailConfig.value) {
          await fetchMailConfig();
        }
        await fetchAndEditExcel();
        toast.success("Email sent successfully!");
      } catch (error) {
        console.error('Error submitting form:', error);
        toast.error('Error submitting form');
      }
    };

    const isFormValid = computed(() => {
      return requiredFields.every(field => formData.value[field] !== '');
    });

    onMounted(() => {
      nextTick(() => {
        flatpickr('.datepicker', {
          dateFormat: 'd/m/Y',
          static: true
        });

        const currentDate = new Date();
        const day = String(currentDate.getDate()).padStart(2, '0');
        const month = String(currentDate.getMonth() + 1).padStart(2, '0');
        const year = currentDate.getFullYear();
        formData.value.datetoday = `${day}/${month}/${year}`;
      });
    });

    return {
      formData,
      fieldLabels,
      isPreview,
      isFormValid,
      convertToUppercase,
      handleSubmit,
      previewForm,
      editForm,
      submitForm,
      steps,
      currentStep,
      newHireForm
    };
  }
};
</script>

<style scoped>
.fields {
  margin-bottom: 10px;
}

.message h5 {
  color: red;
}
</style>
