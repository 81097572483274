<template>
  <div class="bg-cover bg-center h-full text-gray-900">
    <div class="h-full w-full flex text-center justify-center items-center">
      <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
        <img :src="loadingGif" alt="Loading..." class="h-28" />
        <p class="text-white ml-3">Loading...</p>
      </div>
      <div v-if="!isAuthenticated" class="glassmorphism-container p-6 rounded-md shadow-md m-4 text-center md:w-96">
        <div class="flex items-center w-full justify-center mb-10">
          <img :src="logo" alt="Logo" class="h-24" />
        </div>
        <h2 class="text-2xl mb-10">Login to the Intranet</h2>
        <div class="relative mb-4">
          <input
            v-model="username"
            type="text"
            placeholder="Username"
            required
            @keydown.enter="login"
            class="w-full p-2 border-2 rounded-md border-gray-900 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 placeholder-gray-900"
          />
          <i class="fa fa-user absolute right-3 top-1/2 transform -translate-y-1/2 text-gray-900"></i>
        </div>
        <div class="relative mb-4">
          <input
            v-model="password"
            :type="showPassword ? 'text' : 'password'"
            placeholder="Password"
            required
            @keydown.enter="login"
            class="w-full p-2 border-2 rounded-md border-gray-900 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 placeholder-gray-900"
          />
          <i
            :class="[showPassword ? 'fa-eye-slash' : 'fa-eye', 'fa', 'absolute', 'right-3', 'top-1/2', 'transform', '-translate-y-1/2', 'text-gray-900', 'cursor-pointer']"
            @click="togglePasswordVisibility"
          ></i>
        </div>
        <p v-if="errorMessage" class="text-red-500 mt-2">{{ errorMessage }}</p>
        <div class="flex flex-col">
          <button @click="openForgotPasswordDialog" class="text-red-500 mb-2 underline">Forgot Password</button>
          <button @click="login" class="bg-company-color-1 hover:bg-company-color-2 text-white py-2 px-4 rounded-md">Login</button>
        </div>
      </div>
    </div>

    <!-- Forgot Password Dialog -->
    <div v-if="showForgotPasswordDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Forgot Password</h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500 dark:text-gray-400">Please contact the IT department for assistance with resetting your password.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="closeForgotPasswordDialog" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 sm:ml-3 sm:w-auto sm:text-sm">
              OK
            </button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { query, where, collection, getDocs, limit } from "firebase/firestore";
import { db } from "@/firebase/init";
import { useRouter } from 'vue-router';
import logo from '@/assets/images/logo.png';
import loadingGif from '@/assets/images/loading.gif';
import { getLocalStorageWithTimeout, setLocalStorageWithTimeout } from '@/utils/localStorageUtil';

export default {
  name: "Login",
  setup() {
    const router = useRouter();
    const username = ref('');
    const password = ref('');
    const errorMessage = ref('');
    const showPassword = ref(false);
    const loading = ref(false);
    const isAuthenticated = ref(false);
    const showForgotPasswordDialog = ref(false);

    const isEditConfig = ref(false);
    const isMakeAnnouncement = ref(false);
    const isEditCalendar = ref(false);
    const isEditPDF = ref(false);
    const isEditEmployeeContacts = ref(false);
    const isHRTemplates = ref(false);
    const isJobDescriptions = ref(false);
    const isEditHRForm = ref(false);
    const isEditFinanceForm = ref(false);

    const accessRightVariables = {
      "Edit Config": isEditConfig,
      "Make Announcement": isMakeAnnouncement,
      "Edit Calendar": isEditCalendar,
      "Edit PDF": isEditPDF,
      "Edit Employee Contacts": isEditEmployeeContacts,
      "HR Templates": isHRTemplates,
      "Job Descriptions": isJobDescriptions,
      "Edit HR Form": isEditHRForm,
      "Edit Finance Form": isEditFinanceForm
    };

    const togglePasswordVisibility = () => {
      showPassword.value = !showPassword.value;
    };

    const login = async () => {
      loading.value = true;
      try {
        const q = query(collection(db, 'employees'),
          where('email', '==', username.value),
          where("password", "==", password.value),
          limit(1));
        const userDoc = await getDocs(q);

        if (userDoc.empty) {
          throw new Error('User not found');
        }
        const employee = userDoc.docs[0].data();

              setLocalStorageWithTimeout('isLoginAuthenticated', true);
              setLocalStorageWithTimeout('loggedInEmployeeEmail', username.value);
        setLocalStorageWithTimeout('loggedInAccessRights', employee.accessRights);

        employee.accessRights.forEach(right => {
                if (accessRightVariables[right]) {
                  setLocalStorageWithTimeout(right, true);
                  accessRightVariables[right].value = true;
                }
              });

              isAuthenticated.value = true;

              Object.keys(accessRightVariables).forEach(right => {
                const accessValue = getLocalStorageWithTimeout(right, false);
                accessRightVariables[right].value = accessValue;
              });
        
              router.push('/Dashboard');
      } catch (error) {
        console.error(error);
        errorMessage.value = 'Error fetching authentication data';
      } finally {
        loading.value = false;
      }
    };

    const openForgotPasswordDialog = () => {
      showForgotPasswordDialog.value = true;
    };

    const closeForgotPasswordDialog = () => {
      showForgotPasswordDialog.value = false;
    };

    onMounted(() => {
      isAuthenticated.value = getLocalStorageWithTimeout('isLoginAuthenticated', false);
      if (isAuthenticated.value) {
        router.push('/Dashboard');
      }
    });

    return {
      login,
      username,
      password,
      errorMessage,
      logo,
      showPassword,
      togglePasswordVisibility,
      loading,
      loadingGif,
      isAuthenticated,
      showForgotPasswordDialog,
      openForgotPasswordDialog,
      closeForgotPasswordDialog,
      isEditConfig,
      isMakeAnnouncement,
      isEditCalendar,
      isEditPDF,
      isEditEmployeeContacts,
      isHRTemplates,
      isJobDescriptions,
      isEditHRForm,
      isEditFinanceForm
    };
  }
};

</script>


<style scoped>
.bg-cover {
  background-image: url('@/assets/images/loginbg2.jpg');
  background-size: cover;
  background-position: center;
}

.glassmorphism-container {
  background: rgba(255, 255, 255, 0.1);
  border-radius: 16px;
  box-shadow: 0 4px 30px rgba(0, 0, 0, 0.1);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
  border: 1px solid rgba(255, 255, 255, 0.3);
}

:deep(.placeholder-gray-100::placeholder) {
  color: #f7fafc;
}
</style>
