<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Announcements</li>
        <li v-if="isMakeAnnouncement"><span class="mx-2">></span></li>
        <li v-if="isMakeAnnouncement">
          <router-link to="/MakeAnnouncement" class="text-red-500 hover:text-red-300">Edit and Make Announcements</router-link>
        </li>
      </ol>
    </nav>
    <div class="flex flex-col items-center justify-center p-4">
      <div class="w-full 2xl:w-3/4 mx-auto mt-8">
        <div class="flex justify-between items-center mb-4">
          <h2 class="font-bold text-2xl md:text-3xl">Announcements <i class="fas fa-bullhorn text-company-color-1"></i></h2>
        </div>
        <div class="flex flex-col md:flex-row justify-between items-center mb-4">
          <div class="w-full mb-2 md:mb-0">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search for announcements..."
              class="w-full p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
            />
          </div>
        </div>
        <div class="flex flex-wrap -mx-2">
          <div v-for="(announcement, index) in filteredAnnouncements" :key="announcement.id" :class="index === 0 ? 'w-full mb-4 px-2' : 'w-full md:w-1/2 lg:w-1/3 mb-4 px-2'">
            <router-link :to="`/AnnouncementDisplay/${announcement.id}`" class="bg-white dark:bg-gray-800 shadow-lg rounded-lg overflow-hidden relative group h-full flex flex-col">
              <div class="relative h-48 overflow-hidden">
                <img :src="announcement.imageUrl || defaultImage" alt="Announcement Image" class="object-cover w-full h-full group-hover:blur-md transition duration-300">
              </div>
              <div class="p-4 flex flex-col flex-grow justify-between group-hover:blur-md transition duration-300">
                <div>
                  <h3 class="font-bold text-xl md:text-2xl mb-2">{{ announcement.title }}</h3>
                </div>
                <div class="mt-auto">
                  <p class="text-sm md:text-md text-gray-600 dark:text-gray-400">Announcement Date: {{ announcement.date }}</p>
                  <p class="text-sm md:text-md mt-2 text-gray-700 dark:text-gray-300">
                    {{ truncatedDescription(announcement.description) }}
                    <span v-if="isDescriptionTruncated(announcement.description)">...</span>
                  </p>
                </div>
              </div>
              <div class="absolute inset-0 bg-gray-800 bg-opacity-50 flex items-center justify-center text-white text-xl font-bold opacity-0 group-hover:opacity-100 transition-opacity duration-300">
                <div class="rounded-lg p-2 bg-company-color-1">View</div>
              </div>
            </router-link>
          </div>
        </div>
        <div class="flex flex-col md:flex-row justify-between items-center p-4">
          <div class="text-sm lg:text-md mb-4 lg:mb-0">
            Items per page:
            <select v-model="rowsPerPage" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2">
              <option v-for="option in [4, 10]" :key="option" :value="option">{{ option }}</option>
            </select>
          </div>
          <div class="text-sm lg:text-md">
            <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded mr-2">Prev</button>
            {{ paginationInfo }}
            <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded ml-2">Next</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { collection, query, orderBy, onSnapshot } from "firebase/firestore";
import { db } from '@/firebase/init';
import { getLocalStorageWithTimeout } from '@/utils/localStorageUtil';
import defaultImage from '@/assets/images/defaultimage.jpg';

export default {
  data() {
    return {
      announcements: [],
      defaultImage,
      currentPage: 1,
      rowsPerPage: 4,
      searchQuery: '',
      isMakeAnnouncement: false
    };
  },
  computed: {
    filteredAnnouncements() {
      let filtered = this.announcements;
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(announcement => {
          return (
            announcement.title.toLowerCase().includes(query) ||
            announcement.description.toLowerCase().includes(query) ||
            announcement.date.toLowerCase().includes(query)
          );
        });
      }
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return filtered.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.announcements.length / this.rowsPerPage);
    },
    paginationInfo() {
      const start = (this.currentPage - 1) * this.rowsPerPage + 1;
      const end = Math.min(start + this.rowsPerPage - 1, this.announcements.length);
      return `${start} - ${end} of ${this.announcements.length}`;
    }
  },
  methods: {
    fetchData() {
      const q = query(collection(db, "Announcements"), orderBy("date", "desc"));
      onSnapshot(q, (querySnapshot) => {
        this.announcements = [];
        querySnapshot.forEach((doc) => {
          const data = doc.data();
          if (data.title && data.date && data.description) {
            this.announcements.push({ id: doc.id, ...data });
          } else {
            console.error('Missing data fields in document:', doc.id);
          }
        });
      });
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    truncatedDescription(description) {
      const maxLength = 170;
      return description.length > maxLength ? description.slice(0, maxLength) : description;
    },
    isDescriptionTruncated(description) {
      const maxLength = 170;
      return description.length > maxLength;
    }
  },
  mounted() {
    this.fetchData();
    this.isMakeAnnouncement = getLocalStorageWithTimeout('isMakeAnnouncement', false);
  }
};
</script>

<style scoped>
.block {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.bento-box {
  position: relative;
  overflow: hidden;
}
.bento-box img {
  transition: transform 0.5s ease;
}
.bento-box:hover img {
  transform: scale(1.1);
}
.group:hover .opacity-0 {
  opacity: 1 !important;
}
.group:hover .group-hover\:blur-md {
  filter: blur(3px);
}
</style>  
