import { ref } from 'vue';

export const eventBus = ref({
  subscribers: [],
  subscribe(event, callback) {
    this.subscribers.push({ event, callback });
  },
  publish(event, data) {
    this.subscribers.forEach(subscriber => {
      if (subscriber.event === event) {
        subscriber.callback(data);
      }
    });
  },
});
