<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
    <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
      <img :src="loadingGif" alt="Loading..." class="h-28" />
      <p class="text-white ml-3">Loading...</p>
    </div>

    <template v-if="form">
      <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/FinanceForms" class="text-company-color-1 hover:text-company-color-2">Finance Forms</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Edit Finance Form</li>
        </ol>
      </nav>

      <div class="flex flex-col items-center justify-center p-4">
        <div class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full mb-4">
          <h2 class="text-2xl lg:text-3xl font-semibold mb-6">Form Information</h2>
          <form>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2 font-semibold">Title:</label>
              <input v-model="form.title" type="text" class="w-full p-2 rounded-md bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" readonly />
            </div>

            <div v-if="form.type !== 'downloadable'" class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2 font-semibold">Link:</label>
              <input v-model="form.link" type="text" class="w-full p-2 rounded-md bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" readonly />
            </div>

            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2 font-semibold">Image:</label>
              <img :src="form.imageSrc" alt="Form Image" class="rounded-lg mb-4" />
            </div>

            <div class="flex justify-start">
              <button type="button" @click="openEditDialog" class="bg-green-500 hover:bg-green-400 text-white py-2 px-4 rounded-md mr-2">Edit</button>
              <button type="button" @click="openDeleteDialog" class="bg-red-600 hover:bg-red-500 text-white py-2 px-4 rounded-md">Delete</button>
            </div>
          </form>
        </div>

        <div v-if="form.type === 'downloadable'" class="bg-white dark:bg-gray-800 shadow-md rounded-lg p-8 max-w-lg w-full">
          <p class="text-2xl lg:text-3xl font-semibold mb-10">
            Please upload the newest version of the document
          </p>
          <form @submit.prevent="updateDocument" enctype="multipart/form-data">
            <p class="text-sm text-gray-600 dark:text-gray-300 mb-4">
              <span class="text-red-600">Note:</span> The uploaded file should differ from the original file in formatting and styling only, not in the variable aspect for form generation. Contact the <span class="text-blue-400 font-semibold">IT department</span> for changes regarding variables.
            </p>
            <div class="mb-4">
              <label class="block text-gray-700 dark:text-gray-300 mb-2">Upload File</label>
              <div @click="triggerFileInput" @dragover.prevent @dragenter.prevent @drop="onDrop" class="border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700">
                <input type="file" ref="file" @change="handleDocumentUpload" class="hidden" />
                <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                  <i class="fa-solid fa-download"></i>
                </div>
                <p v-if="newDocumentFile" class="mt-2 text-gray-600 dark:text-gray-300">{{ newDocumentFile.name }}</p>
                <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop files here to upload</p>
              </div>
            </div>
            <div v-if="message" class="my-4">
              <h5 class="text-gray-700 dark:text-gray-300">{{ message }}</h5>
            </div>
            <div>
              <button type="submit" class="w-28 bg-company-color-1 text-white py-2 px-4 rounded hover:bg-company-color-2 focus:outline-none focus:shadow-outline">
                Submit
              </button>
            </div>
          </form>
        </div>
      </div>

      <div v-if="showEditDialog" class="fixed z-30 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Edit Form Details</h3>
                  <div class="mt-2">
                    <div class="mb-4">
                      <label class="block text-gray-700 dark:text-gray-300 mb-2">Title:</label>
                      <input v-model="form.title" type="text" class="w-full p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" required />
                    </div>
                    <div v-if="form.type !== 'downloadable'" class="mb-4">
                      <label class="block text-gray-700 dark:text-gray-300 mb-2">Link:</label>
                      <input v-model="form.link" type="text" class="w-full p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" required />
                    </div>
                    <div class="mb-4">
                      <label class="block text-gray-700 dark:text-gray-300 mb-2">Upload New Image</label>
                      <div @click="triggerImageInput" @dragover.prevent @dragenter.prevent @drop="onImageDrop" class="border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700">
                        <input type="file" ref="imageInput" @change="handleImageUpload" accept="image/*" class="hidden" />
                        <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                          <i class="fa-solid fa-image"></i>
                        </div>
                        <p v-if="newImageFile" class="mt-2 text-gray-600 dark:text-gray-300">{{ newImageFile.name }}</p>
                        <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop image here to upload</p>
                      </div>
                      <img v-if="imagePreview" :src="imagePreview" alt="Form Image Preview" class="mt-4 rounded-lg" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="confirmEdit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                Save changes
              </button>
              <button @click="closeEditDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div v-if="showDeleteDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Confirm Delete
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500 dark:text-gray-400">Are you sure you want to delete this form? This action cannot be undone.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="confirmDelete" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Delete
            </button>
            <button @click="closeDeleteDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
          <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
            <img :src="loadingGif" alt="Loading..." class="h-28" />
            <p class="text-white ml-3">Loading...</p>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from "firebase/storage";
import { doc, getDoc, updateDoc, deleteDoc } from "firebase/firestore";
import { storage, db } from "@/firebase/init";
import loadingGif from "@/assets/images/loading.gif";
import { useToast } from "vue-toastification";

export default {
  name: "EditFinanceForm",
  data() {
    return {
      form: null,
      imagePreview: null,
      newImageFile: null,
      newDocumentFile: null,
      message: "",
      loading: false,
      showEditDialog: false,
      showDeleteDialog: false,
      loadingGif,
    };
  },
  async created() {
    this.loading = true;
    const docId = this.$route.params.id;
    const docRef = doc(db, 'Finance_Forms', docId);

    try {
      const docSnap = await getDoc(docRef);
      if (docSnap.exists()) {
        this.form = docSnap.data();
      } else {
        console.error('No such document!');
      }
    } catch (error) {
      console.error('Error fetching document:', error);
    } finally {
      this.loading = false;
    }
  },
  methods: {
    handleImageUpload(event) {
      const file = event.target.files[0];
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

      if (file && validImageTypes.includes(file.type)) {
        this.newImageFile = file;
        this.imagePreview = URL.createObjectURL(file);
        this.message = "";
      } else {
        this.message = "Please upload a valid image file (JPEG, PNG, GIF, WebP)";
        this.newImageFile = null;
        this.imagePreview = null;
      }
    },
    handleDocumentUpload(event) {
      const file = event.target.files[0];
      if (file) {
        this.newDocumentFile = file;
      }
    },
    async updateFormDetails() {
      this.loading = true;
      const toast = useToast();
      const docId = this.$route.params.id;
      const docRef = doc(db, 'Finance_Forms', docId);

      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists()) {
          const oldImageUrl = docSnap.data().imageSrc;

          if (this.newImageFile && oldImageUrl) {
            const oldImagePath = decodeURIComponent(
              oldImageUrl.split('?')[0].replace("https://firebasestorage.googleapis.com/v0/b/YOUR_PROJECT_ID.appspot.com/o/", "").replace(/%2F/g, "/")
            );

            const oldImageRef = storageRef(storage, oldImagePath);
            await deleteObject(oldImageRef);

            const newImageRef = storageRef(storage, `Finance_Forms_Image/${this.newImageFile.name}`);
            await uploadBytes(newImageRef, this.newImageFile);
            const newImageUrl = await getDownloadURL(newImageRef);

            this.form.imageSrc = newImageUrl;
          }

          await updateDoc(docRef, {
            title: this.form.title,
            link: this.form.link,
            imageSrc: this.form.imageSrc,
          });

          toast.success('Form details updated successfully!');
        } else {
          toast.error('Form does not exist.');
        }

        this.closeEditDialog();
      } catch (error) {
        console.error('Error updating form details:', error);
        toast.error('Failed to update form details.');
      } finally {
        this.loading = false;
      }
    },
    async updateDocument() {
      this.loading = true;
      const toast = useToast();
      const docId = this.$route.params.id;
      const docRef = doc(db, 'Finance Forms', docId);

      try {
        const docSnap = await getDoc(docRef);

        if (docSnap.exists() && this.newDocumentFile) {
          const oldDocumentUrl = docSnap.data().link;

          const oldDocumentPath = decodeURIComponent(
            oldDocumentUrl.split('?')[0].replace("https://firebasestorage.googleapis.com/v0/b/YOUR_PROJECT_ID.appspot.com/o/", "").replace(/%2F/g, "/")
          );

          const oldDocumentRef = storageRef(storage, oldDocumentPath);
          await deleteObject(oldDocumentRef);

          const newDocumentRef = storageRef(storage, `HR Forms/${this.newDocumentFile.name}`);
          await uploadBytes(newDocumentRef, this.newDocumentFile);
          const newDocumentUrl = await getDownloadURL(newDocumentRef);
          
          this.form.link = newDocumentUrl;
          await updateDoc(docRef, {
            link: this.form.link,
          });

          toast.success('Document updated successfully!');
        } else {
          toast.error('Document does not exist or no new file selected.');
        }
      } catch (error) {
        console.error('Error updating document:', error);
        toast.error('Failed to update document.');
      } finally {
        this.loading = false;
      }
    },
    openEditDialog() {
      this.showEditDialog = true;
    },
    closeEditDialog() {
      this.showEditDialog = false;
    },
    async confirmEdit() {
      this.updateFormDetails();
    },
    async confirmDelete() {
      const toast = useToast();
      const docId = this.$route.params.id;
      const docRef = doc(db, 'Finance_Forms', docId);

      this.loading = true;

      try {
        const imagePath = decodeURIComponent(this.form.imageSrc.split('?')[0]
          .replace("https://firebasestorage.googleapis.com/v0/b/vilor-intranet.appspot.com/o/", "")
          .replace(/%2F/g, "/")
        );

        const imageRef = storageRef(storage, imagePath);

        await deleteDoc(docRef);

        await deleteObject(imageRef);

        toast.success('Form deleted successfully!');
        this.$router.push('/FinanceForms');
      } catch (error) {
        console.error('Error deleting form:', error);
        toast.error('Failed to delete form.');
      } finally {
        this.loading = false;
        this.closeDeleteDialog();
      }
    },
    openDeleteDialog() {
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    triggerImageInput() {
      this.$refs.imageInput.click();
    },
    onImageDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      const validImageTypes = ['image/jpeg', 'image/png', 'image/gif', 'image/webp'];

      if (file && validImageTypes.includes(file.type)) {
        this.newImageFile = file;
        this.imagePreview = URL.createObjectURL(file);
        this.message = "";
      } else {
        this.message = "Please upload a valid image file (JPEG, PNG, GIF, WebP)";
        this.newImageFile = null;
        this.imagePreview = null;
      }
    },
    triggerFileInput() {
      this.$refs.file.click();
    },
    onDrop(event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      this.newDocumentFile = file;
      this.message = "";
    },
    resetForm() {
      this.newImageFile = null;
      this.newDocumentFile = null;
      this.imagePreview = null;
      this.message = '';
    }
  }
};
</script>

<style scoped>
</style>
