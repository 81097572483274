<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
    <nav class="px-0 pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li>
          <router-link to="/FiscalCalendar" class="text-company-color-1 hover:text-company-color-2">Fiscal Calendar</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Edit Fiscal Calendar</li>
      </ol>
    </nav>

    <div>
      <div class="flex justify-center">
        <div class="flex flex-col w-full xl:w-10/12 p-4">
          <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Edit Fiscal Calendar</h2>
          </div>
          <h3 class="font-bold mb-4 text-lg md:text-xl">Please edit or choose:</h3>
          <div class="bg-white dark:bg-gray-800 rounded-lg">
            <form @submit.prevent="generateDocument" class="space-y-4">
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">
                Fiscal Calendar Information
              </div>
              <div class="overflow-x-auto p-6 md:p-12">
                <div class="min-w-full inline-block align-middle overflow-hidden bg-white dark:bg-gray-800 shadow sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600 text-xs lg:text-lg">
                    <thead class="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Field</th>
                        <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Options</th>
                        <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-600">
                      <tr>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">Payroll Payment</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ fiscalInfo.payrollPayment || 'Not set' }}</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                          <button @click="openEditFiscalDialog('payrollPayment')" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">Payroll Cut-off</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ fiscalInfo.payrollCutoff || 'Not set' }}</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                          <button @click="openEditFiscalDialog('payrollCutoff')" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">Claim Cut-off</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ fiscalInfo.claimCutoff || 'Not set' }}</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                          <button @click="openEditFiscalDialog('claimCutoff')" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded">Edit</button>
                        </td>
                      </tr>
                      <tr>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">Month End Closing Week</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ fiscalInfo.monthEndClosingWeek || 'Not set' }}</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                          <button @click="openEditFiscalDialog('monthEndClosingWeek')" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded">Edit</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
              </div>

              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">
                Public Holiday Information
              </div>

              <div class="overflow-x-auto p-6 md:p-12">
                <div class="min-w-full inline-block align-middle overflow-hidden bg-white dark:bg-gray-800 shadow sm:rounded-lg">
                  <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600 text-xs lg:text-lg">
                    <thead class="bg-gray-50 dark:bg-gray-700">
                      <tr>
                        <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Date</th>
                        <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Day</th>
                        <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Holiday</th>
                        <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                      </tr>
                    </thead>
                    <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-600">
                      <tr v-for="(holiday) in holidays" :key="holiday.id">
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ holiday.date }}</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ holiday.day }}</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ holiday.holiday }}</td>
                        <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                          <button @click="openEditDialog(holiday)" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded mr-1">Edit</button>
                          <button @click="openDeleteDialog(holiday.id)" class="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded">Delete</button>
                        </td>
                      </tr>
                    </tbody>
                  </table>
                </div>
                <h3 class="font-bold mb-4 text-lg md:text-xl mt-6">Add New Holiday</h3>
                <div class="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4">
                  <select v-model="newHoliday.date.day" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full">
                    <option disabled selected value>Day</option>
                    <option v-for="day in 31" :key="day" :value="day">{{ day }}</option>
                  </select>
                  <select v-model="newHoliday.date.month" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full">
                    <option disabled selected hidden value>Month</option>
                    <option value="Jan">Jan</option>
                    <option value="Feb">Feb</option>
                    <option value="Mar">Mar</option>
                    <option value="Apr">Apr</option>
                    <option value="May">May</option>
                    <option value="Jun">Jun</option>
                    <option value="Jul">Jul</option>
                    <option value="Aug">Aug</option>
                    <option value="Sep">Sep</option>
                    <option value="Oct">Oct</option>
                    <option value="Nov">Nov</option>
                    <option value="Dec">Dec</option>
                  </select>
                  <select v-model="newHoliday.day" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full">
                    <option disabled selected hidden value>Day of the Week</option>
                    <option value="Mon">Mon</option>
                    <option value="Tue">Tue</option>
                    <option value="Wed">Wed</option>
                    <option value="Thu">Thu</option>
                    <option value="Fri">Fri</option>
                    <option value="Sat">Sat</option>
                    <option value="Sun">Sun</option>
                  </select>
                  <input v-model="newHoliday.holiday" placeholder="Holiday" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full" />
                  <button @click="addHoliday" class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded">Add</button>
                </div>
              </div>
            </form>
          </div>
        </div>
      </div>

      <div v-if="showEditFiscalDialog" class="fixed z-30 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    Edit Fiscal Information
                  </h3>
                  <div class="mt-2">
                    <template v-if="editFiscalField === 'payrollPayment'">
                      <label for="payrollPayment">Payroll Payment</label>
                      <select v-model="editFiscalValue" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full mb-2">
                        <option value="Last Day of the Month">Last Day of the Month</option>
                        <option value="Last Weekday of the Month">Last Weekday of the Month</option>
                      </select>
                    </template>
                    <template v-if="editFiscalField === 'payrollCutoff'">
                      <label for="payrollCutoff">Payroll Cut-off</label>
                      <select v-model="editFiscalValue" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full mb-2">
                        <option v-for="day in 31" :key="day" :value="day">{{ day }}</option>
                      </select>
                    </template>
                    <template v-if="editFiscalField === 'claimCutoff'">
                      <label for="claimCutoff">Claim Cut-off</label>
                      <select v-model="editFiscalValue" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full mb-2">
                        <option value="Mon">Mon</option>
                        <option value="Tue">Tue</option>
                        <option value="Wed">Wed</option>
                        <option value="Thu">Thu</option>
                        <option value="Fri">Fri</option>
                        <option value="Sat">Sat</option>
                        <option value="Sun">Sun</option>
                      </select>
                    </template>
                    <template v-if="editFiscalField === 'monthEndClosingWeek'">
                      <label for="monthEndClosingWeek">Month End Closing Week</label>
                      <select v-model="editFiscalValue" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full mb-2">
                        <option value="Week 1">Week 1</option>
                        <option value="Week 2">Week 2</option>
                        <option value="Week 3">Week 3</option>
                        <option value="Week 4">Week 4</option>
                      </select>
                    </template>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="confirmEditFiscal" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                Save changes
              </button>
              <button @click="closeEditFiscalDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showEditDialog" class="fixed z-30 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                  <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    Edit Holiday
                  </h3>
                  <div class="mt-2">
                    <div class="flex flex-col lg:flex-row space-y-2 lg:space-y-0 lg:space-x-4">
                      <select v-model="editHoliday.date.day" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full">
                        <option v-for="day in 31" :key="day" :value="day">{{ day }}</option>
                      </select>
                      <select v-model="editHoliday.date.month" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full">
                        <option value="Jan">Jan</option>
                        <option value="Feb">Feb</option>
                        <option value="Mar">Mar</option>
                        <option value="Apr">Apr</option>
                        <option value="May">May</option>
                        <option value="Jun">Jun</option>
                        <option value="Jul">Jul</option>
                        <option value="Aug">Aug</option>
                        <option value="Sep">Sep</option>
                        <option value="Oct">Oct</option>
                        <option value="Nov">Nov</option>
                        <option value="Dec">Dec</option>
                      </select>
                      <select v-model="editHoliday.day" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full">
                        <option value="Mon">Mon</option>
                        <option value="Tue">Tue</option>
                        <option value="Wed">Wed</option>
                        <option value="Thu">Thu</option>
                        <option value="Fri">Fri</option>
                        <option value="Sat">Sat</option>
                        <option value="Sun">Sun</option>
                      </select>
                    </div>
                    <input v-model="editHoliday.holiday" placeholder="Holiday" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2 w-full mt-2" />
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="confirmEdit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
                Save changes
              </button>
              <button @click="closeEditDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>

      <div v-if="showDeleteDialog" class="fixed z-30 inset-0 overflow-y-auto">
        <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
          <div class="fixed inset-0 transition-opacity" aria-hidden="true">
            <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
          </div>
          <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
          <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
            <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
              <div class="sm:flex sm:items-start">
                <div class="mt-3 text-center sm:mt-0 sm:text-left">
                  <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                    Confirm Delete
                  </h3>
                  <div class="mt-2">
                    <p class="text-sm text-gray-500 dark:text-gray-400">Are you sure you want to delete this holiday? This action cannot be undone.</p>
                  </div>
                </div>
              </div>
            </div>
            <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
              <button @click="confirmDelete" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
                Delete
              </button>
              <button @click="closeDeleteDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
                Cancel
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
      <img :src="loadingGif" alt="Loading..." class="h-28" />
      <p class="text-white ml-3">Loading...</p>
    </div>
  </div>
</template>

<script>
import { db } from '@/firebase/init';
import { collection, getDocs, addDoc, doc, updateDoc, deleteDoc, getDoc } from 'firebase/firestore';
import { useToast } from 'vue-toastification';
import loadingGif from '@/assets/images/loading.gif';
export default {
  name: 'EditCalendar',
  data() {
    return {
      holidays: [],
      fiscalInfo: {},
      newHoliday: {
        date: { day: '', month: '' },
        day: '',
        holiday: ''
      },
      showEditDialog: false,
      showDeleteDialog: false,
      showEditFiscalDialog: false,
      editHoliday: {
        id: '',
        date: { day: '', month: '' },
        day: '',
        holiday: ''
      },
      deleteHolidayId: '',
      editFiscalField: '',
      editFiscalValue: '',
      loading: false,
      loadingGif,
    };
  },
  methods: {
    async fetchData() {
      await this.fetchHolidays();
      await this.fetchFiscalInfo();
    },
    async fetchHolidays() {
      const querySnapshot = await getDocs(collection(db, "holidays"));
      this.holidays = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      this.sortHolidays();
    },
    async fetchFiscalInfo() {
      const querySnapshot = await getDocs(collection(db, "fiscalInfo"));
      querySnapshot.forEach(doc => {
        this.fiscalInfo = { ...doc.data(), id: doc.id };
      });
    },
    async addHoliday() {
      this.loading = true;
      try {
        const newHolidayDate = `${this.newHoliday.date.day} ${this.newHoliday.date.month}`;
        const newHoliday = {
          date: newHolidayDate,
          day: this.newHoliday.day,
          holiday: this.newHoliday.holiday
        };
        await addDoc(collection(db, "holidays"), newHoliday);
        this.newHoliday.date.day = '';
        this.newHoliday.date.month = '';
        this.newHoliday.day = '';
        this.newHoliday.holiday = '';
        this.fetchHolidays();
        this.toast.success("Holiday added successfully");
      } catch (error) {
        this.toast.error("Failed to add holiday. Please try again.");
      } finally {
        this.loading = false;
      }
    },
    openEditDialog(holiday) {
      const [day, month] = holiday.date.split(' ');
      this.editHoliday = { ...holiday, date: { day, month } };
      this.showEditDialog = true;
    },
    closeEditDialog() {
      this.showEditDialog = false;
    },
    async confirmEdit() {
      this.loading = true;
      this.closeEditDialog();
      try {
        const updatedDate = `${this.editHoliday.date.day} ${this.editHoliday.date.month}`;
        const holidayDoc = doc(db, "holidays", this.editHoliday.id);
        await updateDoc(holidayDoc, {
          date: updatedDate,
          day: this.editHoliday.day,
          holiday: this.editHoliday.holiday
        });
        this.fetchHolidays();
        
        this.toast.success("Holiday updated successfully");
      } catch (error) {
        this.toast.error("Failed to update holiday. Please try again.");
      } finally {
        this.loading = false;
      }
    },
    openDeleteDialog(id) {
      this.deleteHolidayId = id;
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    async confirmDelete() {
      this.loading = true;
      try {
        const holidayDoc = doc(db, "holidays", this.deleteHolidayId);
        await deleteDoc(holidayDoc);
        this.fetchHolidays();
        this.closeDeleteDialog();
        this.toast.success("Holiday deleted successfully");
      } catch (error) {
        this.toast.error("Failed to delete holiday. Please try again.");
      } finally {
        this.loading = false;
      }
    },
    openEditFiscalDialog(field) {
      this.editFiscalField = field;
      this.editFiscalValue = this.fiscalInfo[field] || '';
      this.showEditFiscalDialog = true;
    },
    closeEditFiscalDialog() {
      this.showEditFiscalDialog = false;
    },
    async confirmEditFiscal() {
      this.loading = true;
      this.closeEditFiscalDialog();
      try {
        const fiscalDoc = doc(db, "fiscalInfo", this.fiscalInfo.id || 'defaultFiscalInfoId');
        const updateData = { [this.editFiscalField]: this.editFiscalValue };
        if (this.fiscalInfo.id) {
          await updateDoc(fiscalDoc, updateData);
        } else {
          await addDoc(collection(db, "fiscalInfo"), updateData);
        }
        this.fetchFiscalInfo();
        
        this.toast.success("Fiscal information updated successfully");
      } catch (error) {
        this.toast.error("Failed to update fiscal information. Please try again.");
      } finally {
        this.loading = false;
      }
    },
    sortHolidays() {
      this.holidays.sort((a, b) => new Date(`${a.date.split(' ')[1]} ${a.date.split(' ')[0]}`) - new Date(`${b.date.split(' ')[1]} ${b.date.split(' ')[0]}`));
    }
  },
  setup() {
    const toast = useToast();
    return {
      toast
    };
  },
  mounted() {
    this.fetchData();
  }
};
</script>

<style scoped>
</style>
