<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
            <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Job Description Library</li>
        </ol>
    </nav>

    <div class="sticky top-0 shadow-lg bg-white dark:bg-gray-800 p-4 mx-4 mt-4 z-10 flex flex-col md:flex-row justify-between items-center rounded-lg">
      <div class="text-center md:text-left">
        <h4 class="text-2xl md:text-xl lg:text-2xl font-semibold">Job Description Library</h4>
        <p class="text-sm md:text-base lg:text-md">Please select the job description that you wish to download</p>
      </div>
      <div class="relative w-full max-w-md mt-4 md:mt-0">
        <input
          v-model="searchQuery"
          type="text"
          placeholder="Search for job descriptions..."
          class="w-full p-2 pl-10 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
        />
        <i class="fa-solid fa-magnifying-glass absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-500"></i>
      </div>
    </div>
    <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 xl:grid-cols-5 gap-4 p-4">
      <div
        v-for="template in filteredTemplates"
        :key="template.id"
        class="bg-white dark:bg-gray-800 py-4 px-2 rounded-md shadow-md flex flex-col items-center min-h-72"
      >
        <div class="front-page text-center flex flex-col justify-between h-full">
          <div>
            <img :src="wordlogo" alt="Word Logo" class="w-1/4 mb-4 mx-auto" />
            <p class="mb-4">{{ template.title }}</p>
          </div>
          <div class="w-full mt-auto flex flex-col 2xl:flex-row 2xl:justify-between space-y-2 2xl:space-y-0 2xl:space-x-2">
            <button @click="downloadTemplate(template.docName)" class="text-sm lg:text-base bg-blue-500 hover:bg-blue-400 text-white text-center py-2 rounded-md w-full 2xl:w-1/2 flex items-center justify-center px-1">
              <i class="fa-solid fa-download mr-1"></i>
              Download
            </button>
            <router-link
              :to="{ name: 'EditJobDescriptions', params: { id: template.id } }"
              class="text-sm lg:text-base bg-green-500 hover:bg-green-300 text-white text-center py-2 rounded-md w-full 2xl:w-1/2 flex items-center justify-center px-1"
            >
              <i class="fa-solid fa-edit mr-1"></i>
              Edit
            </router-link>  
          </div>
        </div>
      </div>

      <router-link to="/AddJobDescriptions" class="bg-gray-200 dark:bg-gray-700 hover:bg-gray-300 dark:hover:bg-gray-600 py-4 px-2 rounded-md shadow-md flex flex-col items-center justify-center min-h-72">
        <div class="text-center flex flex-col justify-center h-full">
          <i class="fa-solid fa-plus text-6xl text-company-color-1 mb-4"></i>
          <p class="text-lg font-semibold">Add New Job Description</p>
        </div>
      </router-link>
    </div>
  </div>
</template>

<script>
import { db, storage } from '@/firebase/init';
import { collection, getDocs } from 'firebase/firestore';
import { ref as storageRef, getDownloadURL } from 'firebase/storage';
import wordlogo from '@/assets/images/wordlogo.png';
import { saveAs } from 'file-saver';
import { useToast } from "vue-toastification";

export default {
  name: 'JobDescriptionLibrary',
  data() {
    return {
      searchQuery: '',
      wordlogo,
      templates: [],
    };
  },
  computed: {
    filteredTemplates() {
      return this.templates.filter(template => 
        template.title && template.title.toLowerCase().includes(this.searchQuery.toLowerCase())
      );
    }
  },
  methods: {
    async fetchJobDescriptions() {
      try {
        const querySnapshot = await getDocs(collection(db, 'Job_Descriptions_Titles'));
        this.templates = querySnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
      } catch (error) {
        console.error("Error fetching job descriptions: ", error);
      }
    },
    async downloadTemplate(docName) {
      const toast = useToast();
      try {
        toast.info('Download started. Please wait...');
        const fileRef = storageRef(storage, `Job Descriptions/${docName}`);
        const url = await getDownloadURL(fileRef);
        const response = await fetch(url);
        const blob = await response.blob();
        saveAs(blob, docName);
        toast.success("Downloaded Successfully!");
      } catch (error) {
        toast.error("Download failed!");
      }
    }
  },
  mounted() {
    this.fetchJobDescriptions();
  },
};
</script>

<style scoped>
</style>
