<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2">
    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
      <ol class="list-reset flex text-gray-700 dark:text-gray-300">
        <li>
          <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li>
          <router-link to="/HRTemplates" class="text-company-color-1 hover:text-company-color-2">HR
            Templates</router-link>
        </li>
        <li>
          <span class="mx-2">></span>
        </li>
        <li class="text-gray-500 dark:text-gray-400">Exit Interview Form</li>
      </ol>
    </nav>
    <div class="flex flex-col 2xl:flex-row justify-between p-4">
      <div class="w-full 2xl:w-7/12 mx-auto lg:mr-4">
        <div class="flex justify-between items-center mb-4">
          <h2 class="font-bold text-2xl md:text-3xl">Exit Interview Form</h2>
        </div>
        <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
        <div class="bg-white dark:bg-gray-800 rounded-lg">
          <form @submit.prevent="generateDocument" class="space-y-4">
            <div
              class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">
              Employee Contacts</div>
            <fieldset class="rounded p-2 md:p-8">
              <div class="grid md:grid-cols-2 gap-4 p-4">
                <div>
                  <label for="employeename" class="block">Full Name:</label>
                  <input type="text" v-model="formData.employeename" id="employeename" @input="convertToUppercase"
                    required
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="employeenumber" class="block">Employee Number:</label>
                  <input type="text" v-model="formData.employeenumber" id="employeenumber" required
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="department" class="block">Department:</label>
                  <select v-model="formData.department" id="department"
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                    <option value="Administration">Administration</option>
                    <option value="Finance">Finance</option>
                    <option value="Human Resources">Human Resources</option>
                    <option value="Information Technology">Information Technology</option>
                    <option value="Marketing">Marketing</option>
                    <option value="Sales">Sales</option>
                  </select>
                </div>
                <div>
                  <label for="jobtitle" class="block">Job Title (Position):</label>
                  <input type="text" v-model="formData.jobtitle" id="jobtitle" required
                    class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                </div>
                <div>
                  <label for="joineddate" class="block">Joined Date:</label>
                  <input type="text" v-model="formData.joineddate" id="joineddate" required
                    class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
                    placeholder="Select Date">
                </div>
                <div>
                  <label for="lastservicedate" class="block">Date Resigned:</label>
                  <input type="text" v-model="formData.lastservicedate" id="lastservicedate" required
                    class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
                    placeholder="Select Date">
                </div>
                <div>
                  <label for="lastworkingdate" class="block">Physical Last Date:</label>
                  <input type="text" v-model="formData.lastworkingdate" id="lastworkingdate" required
                    class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
                    placeholder="Select Date">
                </div>
                <div>
                  <label for="dateofsignature" class="block">Date of Signature:</label>
                  <input type="text" v-model="formData.dateofsignature" id="dateofsignature" required
                    class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
                    placeholder="Select Date">
                </div>
              </div>
            </fieldset>

            <div class="flex justify-between items-center p-8 pt-0">
              <div>
                <button type="submit"
                  class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto">Download
                  Document</button>
                <input type="reset"
                  class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto" value="Reset">
              </div>
            </div>
          </form>
        </div>
      </div>
      <div class="w-full 2xl:w-5/12 mx-auto flex 2xl:justify-end 2xl:mt-20 ">
        <div
          class="max-w-md h-fit bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 rounded-lg p-8 mt-4 2xl:sticky 2xl:top-10">
          <h3 class="font-bold text-3xl">Note:</h3>
          <ul class="list-disc pl-4 mt-4">
            <li class="pb-3">The required information shall be completed to generate the <span
                class="text-company-color-1"><b>Exit Interview Form</b></span> in Microsoft Excel.</li>
            <li class="pb-3">Please review the document before printing it out for signature.</li>
            <li class="pb-3">The signed copy shall be kept in <span class="text-company-color-1"><u><a
                    href="https://vilor2016.sharepoint.com/sites/VilorBerhad/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FVilorBerhad%2FShared%20Documents%2FHR&viewid=cd7c884f%2Da1f7%2D45d2%2D8c75%2D7dadd4531fe9"
                    target="_blank">Vilor's SharePoint</a></u></span> for record retention.</li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ref, onMounted } from 'vue';
import { saveAs } from 'file-saver';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
import { useToast } from 'vue-toastification';
import 'vue-toastification/dist/index.css';

export default {
  setup() {
    const formData = ref({
      employeename: '',
      employeenumber: '',
      jobtitle: '',
      department: 'Administration',
      joineddate: '',
      lastservicedate: '',
      lastworkingdate: '',
      dateofsignature: ''
    });

    const toast = useToast();

    const convertToUppercase = (event) => {
      event.target.value = event.target.value.toUpperCase();
    };

    const fetchTemplateUrl = async () => {
      const storage = getStorage();
      const templateRef = storageRef(storage, 'HR Templates/Exit-Interview-Form-Template.xlsx');
      const url = await getDownloadURL(templateRef);
      return url;
    };

    const generateDocument = async () => {
      try {
        toast.info('Processing your request. Please wait...');
        
        const templateUrl = await fetchTemplateUrl();
        console.log('Template URL:', templateUrl); 
        
        const response = await fetch('/php/toExcel.php', {
          method: 'POST',
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          },
          body: new URLSearchParams({
            employeename: formData.value.employeename,
            employeenumber: formData.value.employeenumber,
            department: formData.value.department,
            jobtitle: formData.value.jobtitle,
            joineddate: formData.value.joineddate,
            lastservicedate: formData.value.lastservicedate,
            lastworkingdate: formData.value.lastworkingdate,
            dateofsignature: formData.value.dateofsignature,
            templateUrl: templateUrl 
          })
        });

        if (!response.ok) {
          throw new Error('Network response was not ok');
        }

        const blob = await response.blob();
        saveAs(blob, `Exit-Interview-Form_${formData.value.employeename.toUpperCase()}.xlsx`);
        toast.success('Document downloaded successfully!');
      } catch (error) {
        toast.error('Error generating document');
        console.error('Error generating document:', error);
      }
    };

    onMounted(() => {
      flatpickr('.datepicker', {
        dateFormat: 'd/m/Y',
        static: true
      });
    });

    return {
      formData,
      convertToUppercase,
      generateDocument
    };
  }
};
</script>



<style scoped>
.fields {
  margin-bottom: 10px;
}

.message h5 {
  color: red;
}
</style>