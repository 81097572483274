<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2">
      <nav class= "pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
                <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/HRTemplates" class="text-company-color-1 hover:text-company-color-2">HR Templates</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Compensation Change Letter</li>
        </ol>
      </nav>
      <div class="flex flex-col 2xl:flex-row justify-between p-4">
        <div class="w-full 2xl:w-7/12 mx-auto lg:mr-4">
          <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Compensation Change Letter</h2>
          </div>
          <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
          <div class="bg-white dark:bg-gray-800 rounded-lg">
            <form @submit.prevent="generateDocument" class="space-y-4">
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Employee Contacts</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 p-4">
                  <div class="select-container">
                    <label for="employeetitle" class="block">Courtesy Title:</label>
                    <select v-model="formData.employeetitle" id="employeetitle" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="employeelastname" class="block">Last Name:</label>
                    <input type="text" v-model="formData.employeelastname" id="employeelastname" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="employeename" class="block">Full Name:</label>
                    <input type="text" v-model="formData.employeename" id="employeename" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="employeeic" class="block">IC Number:</label>
                    <input type="text" v-model="formData.employeeic" id="employeeic" required placeholder="XXXXXX-XX-XXXX" pattern="[0-9]{6}-[0-9]{2}-[0-9]{4}" title="Enter your IC number in the format: XXXXXX-XX-XXXX" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>
                
                <div class="grid md:grid-cols-2 gap-4 mt-4 p-4">
                  <div>
                    <label for="employeenumber" class="block">Employee Number:</label>
                    <input type="text" v-model="formData.employeenumber" id="employeenumber" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="jobtitle" class="block">Job Title:</label>
                    <input type="text" v-model="formData.jobtitle" id="jobtitle" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div class="select-container">
                    <label for="employeetype" class="block">Type:</label>
                    <select v-model="formData.employeetype" id="employeetype" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Full Time">Full Time</option>
                      <option value="Part Time">Part Time</option>
                      <option value="Fixed Term">Fixed Term</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                      <i class="fa-solid fa-caret-down"></i>
                    </div>
                  </div>
                  <div class="select-container">
                    <label for="jobgrade" class="block">Job Grade:</label>
                    <select v-model="formData.jobgrade" id="jobgrade" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="(A3)">A3</option>
                      <option value="(B1)">B1</option>
                      <option value="(B2)">B2</option>
                      <option value="(B3)">B3</option>
                      <option value="(C1)">C1</option> 
                      <option value="(C2)">C2</option>
                      <option value="(C3)">C3</option>
                      <option value="(D1)">D1</option>
                      <option value="(D2)">D2</option>
                      <option value="(D3)">D3</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="monthlybasesalary" class="block">Monthly Base Salary (RM):</label>
                    <input type="text" v-model="formData.monthlybasesalary" id="monthlybasesalary" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="monthlyallowance" class="block">Monthly Allowance (RM):</label>
                    <input type="text" v-model="formData.monthlyallowance" id="monthlyallowance" placeholder="<Optional>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>  
                </div>

                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                  <div class="grid md:grid-cols-2 gap-4 p-4">
                    <div class="flex items-center">
                      <label for="meritincrease">Merit Increase</label>
                    </div>
                    <div class="flex items-center">
                      <input type="checkbox" id="meritincrease" v-model="formData.meritincrease" class="ml-auto">
                    </div>  
                  </div>
                  <div v-show="formData.meritincrease" class="grid md:grid-cols-2 gap-4 p-4">
                    <div>
                      <label for="basesalaryincreaseamount1" class="block">Base Salary Increase Amount (RM):</label>
                      <input type="text" v-model="formData.basesalaryincreaseamount1" id="basesalaryincreaseamount1" placeholder="<Required>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                    <div>
                      <label for="allowanceincreaseamount1" class="block">Allowance Increase Amount (RM):</label>
                      <input type="text" v-model="formData.allowanceincreaseamount1" id="allowanceincreaseamount1" placeholder="<Optional>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                  </div>
                </fieldset>
                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                  <div class="grid md:grid-cols-2 gap-4 p-4">
                    <div class="flex items-center">
                      <label for="promotion">Promotion</label>
                    </div>
                    <div class="flex items-center">
                      <input type="checkbox" id="promotion" v-model="formData.promotion" class="ml-auto">
                    </div>
                  </div>
                  <div v-show="formData.promotion" class="grid md:grid-cols-2 gap-4 p-4">
                    <div>
                      <label for="basesalaryincreaseamount2" class="block">Base Salary Increase Amount (RM):</label>
                      <input type="text" v-model="formData.basesalaryincreaseamount2" id="basesalaryincreaseamount2" placeholder="<Required>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                    <div>
                      <label for="allowanceincreaseamount1" class="block">Allowance Increase Amount (RM):</label>
                      <input type="text" v-model="formData.allowanceincreaseamount2" id="allowanceincreaseamount2" placeholder="<Optional>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                  </div>
                </fieldset>
                <fieldset class="rounded p-2 md:p-2 border-2 border-gray-200 dark:border-gray-600 mt-4">
                  <div class="grid md:grid-cols-2 gap-4 p-4">
                    <div class="flex items-center">
                      <label for="marketlevelling">Market Levelling</label>
                    </div>
                    <div class="flex items-center">
                      <input type="checkbox" id="marketlevelling" v-model="formData.marketlevelling" class="ml-auto">
                    </div>
                  </div>
                  <div v-show="formData.marketlevelling" class="grid md:grid-cols-2 gap-4 p-4">
                    <div>
                      <label for="basesalaryincreaseamount3" class="block">Base Salary Increase Amount (RM):</label>
                      <input type="text" v-model="formData.basesalaryincreaseamount3" id="basesalaryincreaseamount3" placeholder="<Required>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                    <div>
                      <label for="allowanceincreaseamount1" class="block">Allowance Increase Amount (RM):</label>
                      <input type="text" v-model="formData.allowanceincreaseamount1" id="allowanceincreaseamount3" placeholder="<Optional>" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                  </div>
                </fieldset>

                <div class="p-4 mt-4"> 
                  <div class="grid md:grid-cols-2 gap-4 mt-4">
                    <div>
                      <label for="effectivedate" class="block">Effective Date:</label>
                      <input type="text" v-model="formData.effectivedate" id="effectivedate" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                    </div>
                    <div>
                      <label for="datetoday" class="block">Date of this Letter:</label>
                      <input type="text" v-model="formData.datetoday" id="datetoday" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                    </div>
                  </div>
                </div>
              </fieldset>
  
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Company Information</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 mt-2 p-4">
                  <div class="select-container">
                    <label for="companyname" class="block">Company Name:</label>
                    <select v-model="formData.companyname" id="companyname" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Vilor Berhad">Vilor Berhad</option>
                      <option value="Vilor Partners Berhad">Vilor Partners Berhad</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="companynamehereinreferredtoas" class="block">Company Name hereinafter referred to as:</label>
                    <input type="text" v-model="formData.companynamehereinreferredtoas" id="companynamehereinreferredtoas" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="signingname" class="block">Signing Name:</label>
                    <input type="text" v-model="formData.signingname" id="signingname" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="signingposition" class="block">Signing Position:</label>
                    <input type="text" v-model="formData.signingposition" id="signingposition" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>
              </fieldset>
  
              <div class="flex justify-between items-center p-8 pt-0">
                <div>
                  <button type="submit" class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto">Download Document</button>
                  <input type="reset" class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto" value="Reset">
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="w-full 2xl:w-5/12 mx-auto flex 2xl:justify-end 2xl:mt-20 ">
          <div class="max-w-md h-fit bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 rounded-lg p-8 mt-4 2xl:sticky 2xl:top-10">
            <h3 class="font-bold text-3xl">Note:</h3>
            <ul class="list-disc pl-4 mt-4">
              <li class="pb-3">The required information shall be completed to generate the <span class="text-company-color-1"><b>Compensation Change Letter</b></span> in Microsoft Word.</li>
              <li class="pb-3">Please review the document before printing it out for signature.</li>
              <li class="pb-3">The signed copy shall be kept in <span class="text-company-color-1"><u><a href="https://vilor2016.sharepoint.com/sites/VilorBerhad/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FVilorBerhad%2FShared%20Documents%2FHR&viewid=cd7c884f%2Da1f7%2D45d2%2D8c75%2D7dadd4531fe9" target="_blank">Vilor's SharePoint</a></u></span> for record retention.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  import { ref, onMounted } from 'vue';
  import PizZip from 'pizzip';
  import Docxtemplater from 'docxtemplater';
  import { saveAs } from 'file-saver';
  import flatpickr from 'flatpickr';
  import 'flatpickr/dist/flatpickr.min.css';
  import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
  import { useToast } from 'vue-toastification';
  import 'vue-toastification/dist/index.css';

  export default {
    setup() {
      const formData = ref({
        employeetitle: 'Mr',
        employeelastname: '',
        employeename: '',
        employeeic: '',
        employeenumber: '',
        employeetype: 'Full Time',
        jobtitle: '',
        type: '',
        jobgrade: '',
        monthlybasesalary: '',
        monthlyallowance: '',
        basesalaryincreaseamount1: '',
        allowanceincreaseamount1: '',
        basesalaryincreaseamount2: '',
        allowanceincreaseamount2: '',
        basesalaryincreaseamount3: '',
        allowanceincreaseamount3: '',
        effectivedate: '',
        datetoday: '',
        companyname: 'Vilor Berhad',
        companynamehereinreferredtoas: '',
        signingname: '',
        signingposition: ''
      });

      const toast = useToast();

      const today = new Date();
      formData.value.datetoday = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`; 

      const convertToUppercase = (event) => {
        event.target.value = event.target.value.toUpperCase();
      };

      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      };

      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      };

      const formatFullDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = date.getDate();
          const month = date.toLocaleString('en-GB', { month: 'long' });
          const year = date.getFullYear();
          const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
              case 1: return 'st';
              case 2: return 'nd';
              case 3: return 'rd';
              default: return 'th';
            }
          };
          return `${day}${daySuffix(day)} ${month} ${year}`;
        }
        return '';
      };

      const formatShortDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = String(date.getDate()).padStart(2, '0');
          const month = date.toLocaleString('en-GB', { month: 'short' });
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        }
        return '';
      };

      const formatCurrency = (amount) => {
        return amount.toLocaleString('en-MY', { style: 'currency', currency: 'MYR' }).replace('MYR', 'RM');
      };

      const fetchTemplateUrl = async () => {
        const storage = getStorage();
        const templateRef = storageRef(storage, 'HR Templates/Compensation-Change-Letter-Template.docx');
        return await getDownloadURL(templateRef);
      };

      const generateDocument = async () => {
        try {
          toast.info('Download started. Please wait...');
          const templateUrl = await fetchTemplateUrl();
          const response = await fetch(templateUrl);
          const templateArrayBuffer = await response.arrayBuffer();
          const zip = new PizZip(templateArrayBuffer);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true
          });

          const formattedEmployeeName = formData.value.employeename.toUpperCase();

          const formattedeffectivedate = formatFullDate(formData.value.effectivedate);
          const formattedeffectivedateShort = formatShortDate(formData.value.effectivedate);
          const dateToday = formatShortDate(formData.value.datetoday);
          const upperCompanyName = formData.value.companyname.toUpperCase();
          const formattedLastName = capitalizeFirstLetter(formData.value.employeelastname);

          const monthlyBasesalary = parseFloat(formData.value.monthlybasesalary) || 0;
          const monthlyAllowance = parseFloat(formData.value.monthlyallowance) || 0;
          const baseSalaryIncreaseAmount1 = parseFloat(formData.value.basesalaryincreaseamount1) || 0;
          const allowanceIncreaseAmount1 = parseFloat(formData.value.allowanceincreaseamount1) || 0;
          const baseSalaryIncreaseAmount2 = parseFloat(formData.value.basesalaryincreaseamount2) || 0;
          const allowanceIncreaseAmount2 = parseFloat(formData.value.allowanceincreaseamount2) || 0;
          const baseSalaryIncreaseAmount3 = parseFloat(formData.value.basesalaryincreaseamount3) || 0;
          const allowanceIncreaseAmount3 = parseFloat(formData.value.allowanceincreaseamount3) || 0;

          let newMonthlyBaseSalary = monthlyBasesalary + baseSalaryIncreaseAmount1 + baseSalaryIncreaseAmount2 + baseSalaryIncreaseAmount3;
          let newMonthlyAllowance = monthlyAllowance + allowanceIncreaseAmount1 + allowanceIncreaseAmount2 + allowanceIncreaseAmount3;

          let baseSalaryIncreasePercentage1 = (baseSalaryIncreaseAmount1 / monthlyBasesalary) * 100;
          let baseSalaryIncreasePercentage2 = (baseSalaryIncreaseAmount2 / monthlyBasesalary) * 100;
          let baseSalaryIncreasePercentage3 = (baseSalaryIncreaseAmount3 / monthlyBasesalary) * 100;

          const dataToSet = {
            ...formData.value,
            effectivedate: formattedeffectivedate,
            formattedeffectivedate: formattedeffectivedateShort,
            datetoday: dateToday,
            uppercompanyname: upperCompanyName,
            employeelastname: formattedLastName,
            monthlybasesalary: formatCurrency(monthlyBasesalary),
            monthlyallowance: monthlyAllowance === 0 ? '-' : formatCurrency(monthlyAllowance),
            basesalaryincreaseamount1: baseSalaryIncreaseAmount1 === 0 ? '-' : formatCurrency(baseSalaryIncreaseAmount1),
            allowanceincreaseamount1: allowanceIncreaseAmount1 === 0 ? '-' : formatCurrency(allowanceIncreaseAmount1),
            basesalaryincreaseamount2: baseSalaryIncreaseAmount2 === 0 ? '-' : formatCurrency(baseSalaryIncreaseAmount2),
            allowanceincreaseamount2: allowanceIncreaseAmount2 === 0 ? '-' : formatCurrency(allowanceIncreaseAmount2),
            basesalaryincreaseamount3: baseSalaryIncreaseAmount3 === 0 ? '-' : formatCurrency(baseSalaryIncreaseAmount3),
            allowanceincreaseamount3: allowanceIncreaseAmount3 === 0 ? '-' : formatCurrency(allowanceIncreaseAmount3),
            basesalaryincreasepercentage1: baseSalaryIncreasePercentage1 === 0 ? '-' : baseSalaryIncreasePercentage1.toFixed(2) + '%',
            basesalaryincreasepercentage2: baseSalaryIncreasePercentage2 === 0 ? '-' : baseSalaryIncreasePercentage2.toFixed(2) + '%',
            basesalaryincreasepercentage3: baseSalaryIncreasePercentage3 === 0 ? '-' : baseSalaryIncreasePercentage3.toFixed(2) + '%',
            newmonthlybasesalary: formatCurrency(newMonthlyBaseSalary),
            newmonthlyallowance: newMonthlyAllowance === 0 ? '-' : formatCurrency(newMonthlyAllowance),
            employeename: formattedEmployeeName
          };

          doc.setData(dataToSet);
          doc.render();

          const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          });

          saveAs(out, `Compensation-Change-Letter_${formData.value.employeename.toUpperCase()}.docx`);
          toast.success('Document downloaded successfully!');
        } catch (error) {
          toast.error('Error generating document');
          console.error('Error generating document:', error);
        }
      };

      onMounted(() => {
        flatpickr('.datepicker', {
          dateFormat: 'd/m/Y',
          static: true
        });
      });

      return {
        formData,
        convertToUppercase,
        generateDocument
      };
    }
  };
</script>
  
<style scoped>
.fields {
  margin-bottom: 10px;
}
.message h5 {
  color: red;
}
.select-container {
  position: relative;
  display: inline-block;
}

.icon-container {
  position: absolute;
  top: 45px;
  transform: translateY(-50%);
  right: 10px;
  pointer-events: none;
}

.custom-select {
  appearance: none;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding-right: 2rem;
}
</style>
  