<template>
  <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2 h-full flex flex-col">

    <div v-if="loading" class="fixed top-0 left-0 w-full h-full bg-gray-800 bg-opacity-85 flex justify-center items-center z-50">
        <img :src="loadingGif" alt="Loading..." class="h-28" />
        <p class="text-white ml-3">Loading...</p>
    </div>

    <nav class="pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
              <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/Announcement" class="text-company-color-1 hover:text-company-color-2">Announcements</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Edit Announcements</li>
        </ol>
    </nav>
    <div>
      <div class="flex flex-col items-center justify-center p-4">
        <div class="w-full 2xl:w-3/4 mx-auto">
          <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Make Announcements</h2>
          </div>
          <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
          <div class="bg-white dark:bg-gray-800 rounded-lg">
            <form @submit.prevent="generateDocument" class="space-y-4">
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Announcement Information</div>
              <fieldset class="rounded p-2 md:p-6">
                <div class="grid md:grid-cols-2 gap-4 mt-4 p-2">
                  <div>
                    <label for="title" class="block">Title:</label>
                    <input type="text" v-model="formData.title" id="title" required placeholder="Enter title of announcement" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="datetoday" class="block">Date of Announcement:</label>
                    <input type="text" v-model="formData.datetoday" id="datetoday" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                  </div>
                </div>
                <div class="p-2 mt-2">
                  <label for="description" class="block">Description:</label>
                  <textarea v-model="formData.description" id="description" required placeholder="Enter description of announcement" rows="4" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"></textarea>
                </div>
                <div class="p-2 mt-2">
                  <label for="announcementImage" class="block">Upload Image:</label>
                  <div @click="triggerFileInput('imageInput')" @dragover.prevent @dragenter.prevent @drop="onFileDrop('imageInput', $event)" class="mt-1 border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700">
                    <input type="file" ref="imageInput" @change="onFileChange($event)" id="ann  uncementImage" class="hidden" accept="image/*">
                    <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                      <i class="fa-solid fa-image"></i>
                    </div>
                    <p v-if="formData.imagePreview" class="mt-2 text-gray-600 dark:text-gray-300">{{ formData.image.name }}</p>
                    <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop image here to upload</p>
                  </div>
                  <div v-if="formData.imagePreview" class="mt-2">
                    <img :src="formData.imagePreview" alt="Announcement Image" class="preview-image" />
                  </div>
                </div>
              </fieldset>

              <div class="flex justify-between items-center p-8 pt-0">
                <div class="w-full">
                  <button type="submit" class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded m-0 my-2 md:m-2 w-full md:w-auto" :disabled="loading">Post</button>
                  <button 
                    type="button" 
                    @click="openEmailDialog" 
                    class="px-4 py-2 rounded m-0 my-2 md:m-2 w-full md:w-auto"
                    :class="{
                      'bg-green-500 hover:bg-green-300 text-white': isFormValid, 
                      'bg-green-300 text-white cursor-not-allowed': !isFormValid
                    }" 
                    :disabled="!isFormValid"
                  >
                    Send Email
                  </button>
                  <button type="button" @click="resetForm" class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded m-0 my-2 md:m-2 w-full md:w-auto">Reset</button>
                </div>
              </div>
            </form>
          </div>
        </div>     
        <div class="w-full 2xl:w-3/4 mx-auto mt-8">
          <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Edit Announcements</h2>
          </div>
          <div class="mb-4">
            <input
              v-model="searchQuery"
              type="text"
              placeholder="Search for announcements..."
              class="w-full p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"
            />
          </div>
          <div class="overflow-x-auto">
            <div class="min-w-full inline-block align-middle overflow-hidden bg-white dark:bg-gray-800 shadow sm:rounded-lg">
              <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600 text-xs lg:text-lg">
                <thead class="bg-gray-50 dark:bg-gray-700">
                  <tr>
                    <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Title</th>
                    <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Date</th>
                    <th class="px-6 py-3 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Actions</th>
                  </tr>
                </thead>
                <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-600">
                  <tr v-for="announcement in filteredAnnouncements" :key="announcement.id">
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ announcement.title }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ announcement.date }}</td>
                    <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                      <button @click="openEditDialog(announcement)" class="bg-green-500 hover:bg-green-300 text-white px-4 py-2 rounded mr-1">Edit</button>
                      <button @click="openDeleteDialog(announcement.id)" class="bg-red-500 hover:bg-red-400 text-white px-4 py-2 rounded">Delete</button>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="ml-2 md:ml-0 flex justify-start md:justify-between p-4 md:items-center">
                <div class="flex flex-col md:flex-row w-fit md:w-full items-center justify-start md:justify-between">
                  <div class="text-sm lg:text-md mb-4 lg:mb-0">
                    Rows per page:
                    <select v-model="rowsPerPage" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2">
                      <option v-for="option in [5, 10, 15, 20]" :key="option" :value="option">{{ option }}</option>
                    </select>
                  </div>
                  <div class="text-sm lg:text-md">
                    <button @click="prevPage" :disabled="currentPage === 1" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded mr-2">Prev</button>
                    {{ paginationInfo }}
                    <button @click="nextPage" :disabled="currentPage === totalPages" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded ml-2">Next</button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showEditDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Edit Announcement
                </h3>
                <div class="mt-2">
                  <div class="grid md:grid-cols-2 gap-4 mt-4 p-2">
                    <div>
                      <label for="editTitle" class="block">Title:</label>
                      <input type="text" v-model="editAnnouncement.title" id="editTitle" required placeholder="Enter title of announcement" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                    </div>
                    <div>
                      <label for="editDate" class="block">Date of Announcement:</label>
                      <input type="text" v-model="editAnnouncement.date" id="editDate" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                    </div>
                  </div>
                  <div class="p-2 mt-2">
                    <label for="editDescription" class="block">Description:</label>
                    <textarea v-model="editAnnouncement.description" id="editDescription" required placeholder="Enter description of announcement" rows="4" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2"></textarea>
                  </div>
                  <div class="p-2 mt-2">
                    <label class="block">Current Image:</label>
                    <div class="mt-1">
                      <div v-if="editAnnouncement.imageUrl">
                        <img :src="editAnnouncement.imageUrl" alt="Current Announcement Image" class="preview-image" />
                      </div>
                      <div v-else>
                        <p>No image posted</p>
                      </div>
                    </div>
                  </div>
                  <div class="p-2 mt-2">
                    <label for="editAnnouncementImage" class="block">Change Image:</label>
                    <div @click="triggerFileInput('editImageInput')" @dragover.prevent @dragenter.prevent @drop="onFileDrop('editImageInput', $event)" class="mt-1 border-2 border-dashed border-gray-300 dark:border-gray-600 p-4 rounded-lg flex flex-col items-center justify-center hover:bg-gray-100 dark:hover:bg-gray-700">
                      <input type="file" ref="editImageInput" @change="onEditFileChange($event)" id="editAnnouncementImage" class="hidden" accept="image/*">
                      <div class="text-lg text-gray-600 dark:text-gray-300 p-4 h-12 w-12 rounded-full focus:outline-none focus:shadow-outline items-center">
                        <i class="fa-solid fa-image"></i>
                      </div>
                      <p v-if="editAnnouncement.imagePreview" class="mt-2 text-gray-600 dark:text-gray-300">{{ editAnnouncement.image.name }}</p>
                      <p v-else class="mt-2 text-gray-600 dark:text-gray-300">Drop image here to upload</p>
                    </div>
                    <div v-if="editAnnouncement.imagePreview" class="mt-2">
                      <img :src="editAnnouncement.imagePreview" alt="New Announcement Image" class="preview-image" />
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="confirmEdit" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm">
              Save changes
            </button>
            <button @click="closeEditDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
          <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
            <img :src="loadingGif" alt="Loading..." class="h-28" />
            <p class="text-white ml-3">Loading...</p>
          </div>
        </div>
      </div>
    </div>

    <div v-if="showDeleteDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-lg sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">
                  Confirm Delete
                </h3>
                <div class="mt-2">
                  <p class="text-sm text-gray-500 dark:text-gray-400">Are you sure you want to delete this announcement? This action cannot be undone.</p>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="confirmDelete" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-red-600 text-base font-medium text-white hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-red-500 sm:ml-3 sm:w-auto sm:text-sm">
              Delete
            </button>
            <button @click="closeDeleteDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-gray-100 dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">
              Cancel
            </button>
          </div>
          <div v-if="loading" class="fixed inset-0 bg-gray-500 bg-opacity-75 flex justify-center items-center">
            <img :src="loadingGif" alt="Loading..." class="h-28" />
            <p class="text-white ml-3">Loading...</p>
          </div>
        </div>
      </div>z``
    </div>

    <div v-if="showEmailDialog" class="fixed z-30 inset-0 overflow-y-auto">
      <div class="flex items-center justify-center min-h-screen px-4 text-center sm:block sm:p-0">
        <div class="fixed inset-0 transition-opacity" aria-hidden="true">
          <div class="absolute inset-0 bg-gray-500 opacity-75"></div>
        </div>
        <span class="hidden sm:inline-block sm:align-middle sm:h-screen" aria-hidden="true">&#8203;</span>
        <div class="inline-block align-bottom bg-white dark:bg-gray-800 rounded-lg text-left overflow-hidden shadow-xl transform transition-all sm:my-8 sm:align-middle sm:max-w-2xl sm:w-full">
          <div class="bg-white dark:bg-gray-800 px-4 pt-5 pb-4 sm:p-6 sm:pb-4">
            <div class="sm:flex sm:items-start">
              <div class="mt-3 text-center sm:mt-0 sm:text-left w-full">
                <h3 class="text-lg leading-6 font-medium text-gray-900 dark:text-gray-100">Send Announcement via Email</h3>
                <div class="mt-2">
                  <input
                    type="text"
                    v-model="emailSearchQuery"
                    placeholder="Search employees"
                    class="w-full p-2 border-2 rounded-md border-gray-300 bg-transparent h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 mb-4"
                  />
                  <div class="overflow-x-auto">
                    <table class="min-w-full divide-y divide-gray-200 dark:divide-gray-600 text-xs lg:text-lg">
                      <thead class="bg-gray-50 dark:bg-gray-700">
                        <tr>
                          <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Name</th>
                          <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">Department</th>
                          <th class="px-2 lg:px-6 py-2 lg:py-4 text-left text-xs lg:text-lg font-medium text-gray-500 uppercase tracking-wider">
                            <input type="checkbox" :checked="allSelected" @change="toggleSelectAll($event)" />
                          </th>
                        </tr>
                      </thead>
                      <tbody class="bg-white dark:bg-gray-800 divide-y divide-gray-200 dark:divide-gray-600">
                        <tr v-for="employee in filteredEmailEmployees" :key="employee.id">
                          <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.name }}</td>
                          <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">{{ employee.department }}</td>
                          <td class="px-2 lg:px-6 py-2 lg:py-4 whitespace-nowrap">
                            <input type="checkbox" :checked="isEmailSelected(employee.email)" @change="toggleEmailSelection($event, employee.email)" />
                          </td>
                        </tr>
                      </tbody>
                    </table>
                    <div class="ml-8 md:ml-0 flex justify-start md:justify-between p-4 md:items-center">
                      <div class="flex flex-col md:flex-row w-fit md:w-full items-center justify-start md:justify-between">
                        <div class="text-sm lg:text-md mb-4 lg:mb-0">
                          Rows per page:
                          <select v-model="emailRowsPerPage" class="bg-white dark:bg-gray-700 border border-gray-300 dark:border-gray-600 rounded px-4 py-2">
                            <option v-for="option in [5, 10, 15, 20]" :key="option" :value="option">{{ option }}</option>
                          </select>
                        </div>
                        <div class="text-sm lg:text-md">
                          <button @click="prevEmailPage" :disabled="emailCurrentPage === 1" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded mr-2">Prev</button>
                          {{ emailPaginationInfo }}
                          <button @click="nextEmailPage" :disabled="emailCurrentPage === totalEmailPages" class="px-4 py-2 border border-gray-300 dark:border-gray-600 rounded ml-2">Next</button>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="bg-white dark:bg-gray-800 px-4 py-3 sm:px-6 sm:flex sm:flex-row-reverse">
            <button @click="sendEmail" type="button" class="w-full inline-flex justify-center rounded-md border border-transparent shadow-sm px-4 py-2 bg-blue-500 text-base font-medium text-white hover:bg-blue-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-blue-500 sm:ml-3 sm:w-auto sm:text-sm" :disabled="!isFormValid">Send Email</button>
            <button @click="closeEmailDialog" type="button" class="mt-3 w-full inline-flex justify-center rounded-md shadow-sm px-4 py-2 bg-white dark:bg-gray-700 text-base font-medium text-gray-700 dark:text-gray-300 hover:bg-gray-50 dark:hover:bg-gray-600 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-indigo-500 sm:mt-0 sm:w-auto sm:text-sm">Cancel</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { nextTick } from 'vue';
import flatpickr from 'flatpickr';
import 'flatpickr/dist/flatpickr.min.css';
import { doc, getDoc, addDoc, collection, query, orderBy, onSnapshot, updateDoc, deleteDoc } from "firebase/firestore";
import { db, storage } from '@/firebase/init';
import { useToast } from 'vue-toastification';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage'; 
import loadingGif from '@/assets/images/loading.gif';
import { format } from 'date-fns';

export default {
  directives: {
    flatpickr: {
      mounted(el, binding) {
        flatpickr(el, { ...binding.value });
      },
      updated(el, binding) {
        flatpickr(el, { ...binding.value });
      }
    }
  },
  data() {
    return {
      password: '',
      errorMessage: '',
      isAnouncementAuthenticated: false,
      formData: {
        title: '',
        datetoday: format(new Date(), 'dd/MM/yyyy'),
        description: '',
        image: null,
        imagePreview: null
      },
      editAnnouncement: {
        id: '',
        title: '',
        date: '',
        description: '',
        imageUrl: '',
        image: null,
        imagePreview: null
      },
      announcements: [],
      showEditDialog: false,
      showDeleteDialog: false,
      showEmailDialog: false,
      deleteAnnouncementId: '',
      loading: false,
      loadingGif,
      currentPage: 1,
      rowsPerPage: 10,
      searchQuery: '',
      emailSearchQuery: '',
      emailCurrentPage: 1,
      emailRowsPerPage: 5,
      selectedEmails: [],
      emailEmployees: [],
      departments: [],
      serviceID: '',  
      announcementTemplateID: '', 
      userID: ''    
    };
  },
  computed: {
    filteredAnnouncements() {
      let filtered = this.announcements;
      if (this.searchQuery) {
        const query = this.searchQuery.toLowerCase();
        filtered = filtered.filter(announcement => {
          return (
            announcement.title.toLowerCase().includes(query) ||
            announcement.description.toLowerCase().includes(query) ||
            announcement.date.toLowerCase().includes(query)
          );
        });
      }
      const start = (this.currentPage - 1) * this.rowsPerPage;
      const end = start + this.rowsPerPage;
      return filtered.slice(start, end);
    },
    totalPages() {
      return Math.ceil(this.announcements.length / this.rowsPerPage);
    },
    paginationInfo() {
      const start = (this.currentPage - 1) * this.rowsPerPage + 1;
      const end = Math.min(start + this.rowsPerPage - 1, this.announcements.length);
      return `${start} - ${end} of ${this.announcements.length}`;
    },
    filteredEmailEmployees() {
      let filtered = this.emailEmployees.filter(employee => employee.name !== 'software department admin');

      if (this.emailSearchQuery) {
        filtered = filtered.filter(employee =>
          Object.values(employee).some(value => 
            String(value).toLowerCase().includes(this.emailSearchQuery.toLowerCase())
          )
        );
      }

      const start = (this.emailCurrentPage - 1) * this.emailRowsPerPage;
      const end = start + this.emailRowsPerPage;
      return filtered.slice(start, end);
    },
    totalEmailPages() {
      return Math.ceil(this.emailEmployees.length / this.emailRowsPerPage);
    },
    emailPaginationInfo() {
      const start = (this.emailCurrentPage - 1) * this.emailRowsPerPage + 1;
      const end = Math.min(start + this.emailRowsPerPage - 1, this.filteredEmailEmployees.length);
      return `${start} - ${end} of ${this.filteredEmailEmployees.length}`;
    },
    allSelected() {
      return this.filteredEmailEmployees.every(employee => this.selectedEmails.includes(employee.email));
    },
    isFormValid() {
      return this.formData.title && this.formData.datetoday && this.formData.description;
    }
  },
  methods: {
    triggerFileInput(refName) {
      this.$refs[refName].click();
    },
    onFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.formData.image = file;
        this.formData.imagePreview = URL.createObjectURL(file);
      } else {
        this.toast.error('Please upload a valid image file.');
      }
    },
    onEditFileChange(event) {
      const file = event.target.files[0];
      if (file && file.type.startsWith('image/')) {
        this.editAnnouncement.image = file;
        this.editAnnouncement.imagePreview = URL.createObjectURL(file);
      } else {
        this.toast.error('Please upload a valid image file.');
      }
    },
    async uploadImage(file) {
      const storageRef = ref(storage, `announcements/${file.name}`);
      const snapshot = await uploadBytes(storageRef, file);
      const downloadURL = await getDownloadURL(snapshot.ref);
      return downloadURL;
    },
    async generateDocument() {
      this.loading = true;
      try {
        let imageUrl = '';
        if (this.formData.image) {
          imageUrl = await this.uploadImage(this.formData.image);
        }
        await addDoc(collection(db, "Announcements"), {
          title: this.formData.title,
          date: this.formData.datetoday,
          description: this.formData.description,
          imageUrl
        });
        this.toast.success('Announcement posted successfully!', { timeout: 2000 });
        this.fetchData();
      } catch (error) {
        this.toast.error('Failed to post the announcement. Please try again.', { timeout: 2000 });
      }
      this.loading = false;
    },
    async confirmEdit() {
      this.loading = true;
      this.closeEditDialog();
      try {
        let imageUrl = this.editAnnouncement.imageUrl;
        if (this.editAnnouncement.image) {
          imageUrl = await this.uploadImage(this.editAnnouncement.image);
        }
        const announcementDoc = doc(db, "Announcements", this.editAnnouncement.id);
        await updateDoc(announcementDoc, {
          title: this.editAnnouncement.title,
          date: this.editAnnouncement.date,
          description: this.editAnnouncement.description,
          imageUrl
        });
        this.toast.success('Announcement updated successfully!', { timeout: 2000 });
        this.fetchData();
        
      } catch (error) {
        this.toast.error('Failed to update the announcement. Please try again.', { timeout: 2000 });
      }
      this.loading = false;
    },
    async confirmDelete() {
      this.loading = true;
      try {
        const announcementDoc = doc(db, "Announcements", this.deleteAnnouncementId);
        await deleteDoc(announcementDoc);
        this.toast.success('Announcement deleted successfully!', { timeout: 2000 });
        this.fetchData();
        this.closeDeleteDialog();
      } catch (error) {
        this.toast.error('Failed to delete the announcement. Please try again.', { timeout: 2000 });
      }
      this.loading = false;
    },
    fetchData() {
      const q = query(collection(db, "Announcements"), orderBy("date", "desc"));
      onSnapshot(q, (querySnapshot) => {
        this.announcements = [];
        querySnapshot.forEach((doc) => {
          this.announcements.push({ id: doc.id, ...doc.data() });
        });
      });
      const emailQuery = query(collection(db, "employees"), orderBy("name", "asc"));
      onSnapshot(emailQuery, (querySnapshot) => {
        this.emailEmployees = [];
        querySnapshot.forEach((doc) => {
          this.emailEmployees.push({ id: doc.id, ...doc.data() });
        });
      });
    },
    async fetchEmailJSConfig() {
      try {
          const docRef = doc(db, 'configuration', 'companyInfo');
          const docSnap = await getDoc(docRef);
          if (docSnap.exists()) {
              const data = docSnap.data().emailJSConfig;
              if (data) {
                  this.serviceID = data.serviceId || '';
                  this.announcementTemplateID = data.announcementemailtemplate || '';
                  this.userID = data.userId || '';
              } else {
                  console.log("No emailJSConfig document found!");
              }
          } else {
              console.log("companyInfo document does not exist!");
          }
      } catch (error) {
          console.error("Error fetching emailJSConfig: ", error);
      }
    },

    openEditDialog(announcement) {
      this.editAnnouncement = { ...announcement };
      this.showEditDialog = true;
      nextTick(() => {
        flatpickr('#editDate', { dateFormat: 'd/m/Y' });
      });
    },
    closeEditDialog() {
      this.showEditDialog = false;
      if (this.editAnnouncement.imagePreview) {
        URL.revokeObjectURL(this.editAnnouncement.imagePreview);
        this.editAnnouncement.imagePreview = null;
      }
    },
    openDeleteDialog(id) {
      this.deleteAnnouncementId = id;
      this.showDeleteDialog = true;
    },
    closeDeleteDialog() {
      this.showDeleteDialog = false;
    },
    openEmailDialog() {
      this.showEmailDialog = true;
    },
    closeEmailDialog() {
      this.showEmailDialog = false;
    },
    resetForm() {
      this.formData.title = '';
      this.formData.datetoday = format(new Date(), 'dd/MM/yyyy');
      this.formData.description = '';
      this.formData.image = null;
      if (this.formData.imagePreview) {
        URL.revokeObjectURL(this.formData.imagePreview);
        this.formData.imagePreview = null;
      }
    },
    nextPage() {
      if (this.currentPage < this.totalPages) {
        this.currentPage++;
      }
    },
    prevPage() {
      if (this.currentPage > 1) {
        this.currentPage--;
      }
    },
    nextEmailPage() {
      if (this.emailCurrentPage < this.totalEmailPages) {
        this.emailCurrentPage++;
      }
    },
    prevEmailPage() {
      if (this.emailCurrentPage > 1) {
        this.emailCurrentPage--;
      }
    },
    toggleSelectAll(event) {
      if (event.target.checked) {
        this.filteredEmailEmployees.forEach(employee => {
          if (!this.selectedEmails.includes(employee.email)) {
            this.selectedEmails.push(employee.email);
          }
        });
      } else {
        this.filteredEmailEmployees.forEach(employee => {
          const index = this.selectedEmails.indexOf(employee.email);
          if (index > -1) {
            this.selectedEmails.splice(index, 1);
          }
        });
      }
    },
    toggleEmailSelection(event, email) {
      if (event.target.checked) {
        if (!this.selectedEmails.includes(email)) {
          this.selectedEmails.push(email);
        }
      } else {
        const index = this.selectedEmails.indexOf(email);
        if (index > -1) {
          this.selectedEmails.splice(index, 1);
        }
      }
    },
    isEmailSelected(email) {
      return this.selectedEmails.includes(email);
    },

    async sendEmail() {
      this.loading = true;
      this.closeEmailDialog();

      try {
          const formData = new FormData();
          formData.append('title', this.formData.title);
          formData.append('date', this.formData.datetoday);
          formData.append('description', this.formData.description);
          formData.append('recipients', JSON.stringify(this.selectedEmails.map(email => {
              const employee = this.emailEmployees.find(emp => emp.email === email);
              return { name: employee.name, email: employee.email };
          })));

          if (this.formData.image) {
              formData.append('image', this.formData.image);
          }

          console.log("Sending FormData...");
          for (let pair of formData.entries()) {
              console.log(`${pair[0]}: ${pair[1]}`);
          }

          const response = await fetch('/php/SendAnnouncement.php', {
              method: 'POST',
              body: formData
          });

          const result = await response.json();
          if (result.success) {
              this.toast.success(result.message, { timeout: 2000 });
          } else {
              this.toast.error(result.message, { timeout: 2000 });
          }
      } catch (error) {
          this.toast.error('Failed to send emails. Please try again.', { timeout: 2000 });
      } finally {
          this.loading = false;
      }
  },
    onFileDrop(refName, event) {
      event.preventDefault();
      const file = event.dataTransfer.files[0];
      if (file && file.type.startsWith('image/')) {
        if (refName === 'imageInput') {
          this.formData.image = file;
          this.formData.imagePreview = URL.createObjectURL(file);
        } else if (refName === 'editImageInput') {
          this.editAnnouncement.image = file;
          this.editAnnouncement.imagePreview = URL.createObjectURL(file);
        }
      } else {
        this.toast.error('Please upload a valid image file.');
      }
    }
  },
  mounted() {
    this.toast = useToast();
    this.fetchData();
    flatpickr('.datepicker', { dateFormat: 'd/m/Y', static: true });
  },
  beforeDestroy() {
    if (this.formData.imagePreview) {
      URL.revokeObjectURL(this.formData.imagePreview);
    }
    if (this.editAnnouncement.imagePreview) {
      URL.revokeObjectURL(this.editAnnouncement.imagePreview);
    }
  }
};
</script>

<style scoped>
.preview-image {
  max-width: 100%;
  max-height: 150px;
  display: block;
  margin-top: 10px;
}
</style>
