<template>
    <div class="bg-gray-100 dark:bg-gray-900 text-gray-800 dark:text-gray-100 transition-colors duration-300 md:p-2">
      <nav class= "pt-2 mb-1 mx-4 text-xs lg:text-md">
        <ol class="list-reset flex text-gray-700 dark:text-gray-300">
          <li>
                <router-link to="/Dashboard" class="text-company-color-1 hover:text-company-color-2">Home</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li>
            <router-link to="/HRTemplates" class="text-company-color-1 hover:text-company-color-2">HR Templates</router-link>
          </li>
          <li>
            <span class="mx-2">></span>
          </li>
          <li class="text-gray-500 dark:text-gray-400">Acceptance of Resignation Letter</li>
        </ol>
      </nav>
      <div class="flex flex-col 2xl:flex-row justify-between p-4">
        <div class="w-full 2xl:w-7/12 mx-auto lg:mr-4">
          <div class="flex justify-between items-center mb-4">
            <h2 class="font-bold text-2xl md:text-3xl">Acceptance of Resignation Letter</h2>
          </div>
          <h3 class="font-bold mb-4 text-lg md:text-xl">Please fill up the information below:</h3>
          <div class="bg-white dark:bg-gray-800 rounded-lg">
            <form @submit.prevent="generateDocument" class="space-y-4">
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Employee Contacts</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 pl-4 pb-4">
                  <div class="select-container">
                    <label for="employeetitle" class="block">Courtesy Title:</label>
                    <select v-model="formData.employeetitle" id="employeetitle" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Mr">Mr</option>
                      <option value="Ms">Ms</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  <div>
                    <label for="employeelastname" class="block">Last Name:</label>
                    <input type="text" v-model="formData.employeelastname" id="employeelastname" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="employeename" class="block">Full Name:</label>
                    <input type="text" v-model="formData.employeename" id="employeename" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="employeeic" class="block">IC Number:</label>
                    <input type="text" v-model="formData.employeeic" id="employeeic" required placeholder="XXXXXX-XX-XXXX" pattern="[0-9]{6}-[0-9]{2}-[0-9]{4}" title="Enter your IC number in the format: XXXXXX-XX-XXXX" class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                </div>
                
                <div class="grid md:grid-cols-2 gap-4 pl-4">
                  <div>
                    <label for="employeenumber" class="block">Employee Number:</label>
                    <input type="text" v-model="formData.employeenumber" id="employeenumber" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="jobtitle" class="block">Job Title (Position):</label>
                    <input type="text" v-model="formData.jobtitle" id="jobtitle" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  <div>
                    <label for="dateofresignationletter" class="block">Date of Resignation Letter:</label>
                    <input type="text" v-model="formData.dateofresignationletter" id="dateofresignationletter" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                  </div>
                  <div>
                    <label for="dateoflastdayofwork" class="block">Date of Last Day of Work:</label>
                    <input type="text" v-model="formData.dateoflastdayofwork" id="dateoflastdayofwork" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                  </div>
                  <div>
                    <label for="datetoday" class="block">Date of this Letter:</label>
                    <input type="text" v-model="formData.datetoday" id="datetoday" required class="datepicker mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2" placeholder="Select Date">
                  </div>
                </div>
              </fieldset>
  
              <div class="font-semibold text-xl md:text-2xl py-4 px-6 md:px-12 border-b-2 border-gray-200 dark:border-gray-600">Company Information</div>
              <fieldset class="rounded p-2 md:p-8">
                <div class="grid md:grid-cols-2 gap-4 mt-2 p-4">
                  
                  <div class="select-container">
                    <label for="companyname" class="block">Company Name:</label>
                    <select v-model="formData.companyname" id="companyname" class="custom-select mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2 dark:bg-gray-800 dark:text-white">
                      <option value="Vilor Berhad">Vilor Berhad</option>
                      <option value="Vilor Partners Berhad">Vilor Partners Berhad</option>
                    </select>
                    <div class="icon-container absolute inset-y-0 right-0 flex items-center px-2 pointer-events-none">
                        <i class="fa-solid fa-caret-down"></i>
                      </div>
                  </div>
                  
                  <div>
                    <label for="signingname" class="block">Signing Name:</label>
                    <input type="text" v-model="formData.signingname" id="signingname" @input="convertToUppercase" required class="mt-1 w-full rounded-lg border-2 border-gray-200 bg-transparent p-2 h-10 outline-none transition focus:border-company-color-2 active:border-company-color-2 dark:border-gray-600 dark:focus:border-company-color-2">
                  </div>
                  
                </div>
              </fieldset>
  
              <div class="flex justify-between items-center p-8 pt-0">
                <div>
                  <button type="submit" class="bg-blue-500 hover:bg-blue-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto">Download Document</button>
                  <input type="reset" class="bg-gray-500 hover:bg-gray-400 text-white px-4 py-2 rounded m-2 w-full md:w-auto" value="Reset">
                </div>
              </div>
            </form>
          </div>
        </div>
        <div class="w-full 2xl:w-5/12 mx-auto flex 2xl:justify-end 2xl:mt-20 ">
          <div class="max-w-md h-fit bg-white dark:bg-gray-800 text-gray-800 dark:text-gray-100 rounded-lg p-8 mt-4 2xl:sticky 2xl:top-10">
            <h3 class="font-bold text-3xl">Note:</h3>
            <ul class="list-disc pl-4 mt-4">
              <li class="pb-3">The required information shall be completed to generate the <span class="text-company-color-1"><b>Acceptance of Resignation Letter</b></span> in Microsoft Word.</li>
              <li class="pb-3">Please review the document before printing it out for signature.</li>
              <li class="pb-3">The signed copy shall be kept in <span class="text-company-color-1"><u><a href="https://vilor2016.sharepoint.com/sites/VilorBerhad/Shared%20Documents/Forms/AllItems.aspx?ga=1&id=%2Fsites%2FVilorBerhad%2FShared%20Documents%2FHR&viewid=cd7c884f%2Da1f7%2D45d2%2D8c75%2D7dadd4531fe9" target="_blank">Vilor's SharePoint</a></u></span> for record retention.</li>
            </ul>
          </div>
        </div>
      </div>
    </div>
</template>
  
<script>
  import { ref, onMounted } from 'vue';
  import PizZip from 'pizzip';
  import Docxtemplater from 'docxtemplater';
  import { saveAs } from 'file-saver';
  import flatpickr from 'flatpickr';
  import 'flatpickr/dist/flatpickr.min.css';
  import { getStorage, ref as storageRef, getDownloadURL } from "firebase/storage";
  import { useToast } from 'vue-toastification';
  import 'vue-toastification/dist/index.css';
  
  export default {
    setup() {
      const formData = ref({
        employeetitle: 'Mr',
        employeelastname: '',
        employeename: '',
        employeeic: '',
        employeenumber: '',
        jobtitle: '',
        dateofresignationletter: '',
        dateoflastdayofwork: '',
        datetoday: '',
        companyname: 'Vilor Berhad',
        signingname: '',
      });

      const today = new Date();
      formData.value.datetoday = `${today.getDate()}/${today.getMonth() + 1}/${today.getFullYear()}`; 
  
      const toast = useToast();
  
      const convertToUppercase = (event) => {
        event.target.value = event.target.value.toUpperCase();
      };
  
      const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
      };
  
      const parseDate = (dateString) => {
        const [day, month, year] = dateString.split('/').map(Number);
        return new Date(year, month - 1, day);
      };
  
      const formatFullDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = date.getDate();
          const month = date.toLocaleString('en-GB', { month: 'long' });
          const year = date.getFullYear();
          const daySuffix = (day) => {
            if (day > 3 && day < 21) return 'th';
            switch (day % 10) {
              case 1: return 'st';
              case 2: return 'nd';
              case 3: return 'rd';
              default: return 'th';
            }
          };
          return `${day}${daySuffix(day)} ${month} ${year}`;
        }
        return '';
      };
  
      const formatShortDate = (dateString) => {
        const date = parseDate(dateString);
        if (!isNaN(date)) {
          const day = String(date.getDate()).padStart(2, '0');
          const month = date.toLocaleString('en-GB', { month: 'short' });
          const year = date.getFullYear();
          return `${day}-${month}-${year}`;
        }
        return '';
      };
  
      const fetchTemplateUrl = async () => {
        const storage = getStorage();
        const templateRef = storageRef(storage, 'HR Templates/Acceptance-of-Resignation-Letter-Template.docx');
        return await getDownloadURL(templateRef);
      };
  
      const generateDocument = async () => {
        try {
          toast.info('Download started. Please wait...');
          const templateUrl = await fetchTemplateUrl();
          const response = await fetch(templateUrl);
          const templateArrayBuffer = await response.arrayBuffer();
          const zip = new PizZip(templateArrayBuffer);
          const doc = new Docxtemplater(zip, {
            paragraphLoop: true,
            linebreaks: true
          });
  
          const formatteddateofresignationletter = formatFullDate(formData.value.dateofresignationletter);
          const formatteddateoflastdayofwork = formatFullDate(formData.value.dateoflastdayofwork);
          const dateToday = formatShortDate(formData.value.datetoday);
          const upperCompanyName = formData.value.companyname.toUpperCase();
          const formattedLastName = capitalizeFirstLetter(formData.value.employeelastname);
  
          const dataToSet = {
            ...formData.value,
            dateofresignationletter: formatteddateofresignationletter,
            dateoflastdayofwork: formatteddateoflastdayofwork,
            datetoday: dateToday,
            companyname: upperCompanyName,
            employeelastname: formattedLastName
          };
  
          doc.setData(dataToSet);
          doc.render();
  
          const out = doc.getZip().generate({
            type: 'blob',
            mimeType: 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
          });
  
          saveAs(out, `Acceptance-of-Resignation-Letter_${formData.value.employeename.toUpperCase()}.docx`);
          toast.success('Document downloaded successfully!');
        } catch (error) {
          toast.error('Error generating document');
          console.error('Error generating document:', error);
        }
      };
  
      onMounted(() => {
        flatpickr('.datepicker', {
          dateFormat: 'd/m/Y',
          static: true
        });
      });
  
      return {
        formData,
        convertToUppercase,
        generateDocument
      };
    }
  };
</script>
  
<style scoped>
  .fields {
    margin-bottom: 10px;
  }
  .message h5 {
    color: red;
  }

  .select-container {
    position: relative;
    display: inline-block;
  }

  .icon-container {
    position: absolute;
    top: 45px;
    transform: translateY(-50%);
    right: 10px;
    pointer-events: none;
  }

  .custom-select {
    appearance: none;
    -webkit-appearance: none;
    -moz-appearance: none;
    padding-right: 2rem;
  }
</style>
  