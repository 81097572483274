import { createRouter, createWebHistory } from 'vue-router';
import Dashboard from '../pages/Dashboard.vue';
import InternshipOfferLetter from '../pages/HumanResource/HRTemplates/InternshipOfferLetter.vue';
import HRTemplates from '../pages/HumanResource/HRTemplates/HRTemplates.vue';
import AcceptanceofResignationLetter from '../pages/HumanResource/HRTemplates/AcceptanceofResignationLetter.vue';
import CompensationChangeLetter from '../pages/HumanResource/HRTemplates/CompensationChangeLetter.vue';
import EmployeeHandbookAcknowledgementLetter from '../pages/HumanResource/HRTemplates/EmployeeHandbookAcknowledgementLetter.vue';
import EmployeeNovationAgreement from '../pages/HumanResource/HRTemplates/EmployeeNovationAgreement.vue';
import EmploymentContractLetterGeneral from '../pages/HumanResource/HRTemplates/EmploymentContractLetterGeneral.vue';
import EmploymentContractLetterSales from '../pages/HumanResource/HRTemplates/EmploymentContractLetterSales.vue';
import EmploymentContractLetterMarketing from '../pages/HumanResource/HRTemplates/EmploymentContractLetterMarketing.vue';
import MarketingFixedTerm from '../pages/HumanResource/HRTemplates/MarketingFixedTerm.vue';
import ExitInterviewForm from '../pages/HumanResource/HRTemplates/ExitInterviewForm.vue';
import JobUpdateLetter from '../pages/HumanResource/HRTemplates/JobUpdateLetter.vue';
import ProbationConfirmationLetter from '../pages/HumanResource/HRTemplates/ProbationConfirmationLetter.vue';
import ProbationExtensionLetter from '../pages/HumanResource/HRTemplates/ProbationExtensionLetter.vue';
import ProbationTerminationLetter from '../pages/HumanResource/HRTemplates/ProbationTerminationLetter.vue';
import ShowCauseLetter from '../pages/HumanResource/HRTemplates/ShowCauseLetter.vue';
import WarningLetterMinorMisconduct from '../pages/HumanResource/HRTemplates/WarningLetter-MinorMisconduct.vue';
import WarningLetterMajorMisconduct from '../pages/HumanResource/HRTemplates/WarningLetter-MajorMisconduct.vue';
import WarningLetterNonPerformance from '../pages/HumanResource/HRTemplates/WarningLetter-NonPerformance.vue';
import EditFinanceForms from '../pages/EmployeeForm/FinanceForms/EditFinanceForms.vue';
import AddFinanceForm from '../pages/EmployeeForm/FinanceForms/AddFinanceForm.vue';
import EditHRForms from '../pages/EmployeeForm/HRForms/EditHRForms.vue';
import AddHRForm from '../pages/EmployeeForm/HRForms/AddHRForm.vue';
import EditPDFs from '../pages/Administration/EditPDFs.vue';
import Configurations from '../pages/InformationTechnology/Configurations.vue';
import ChangePassword from '../pages/ChangePassword.vue';
import Introduction from '../pages/OurCompany/Introduction.vue';
import BusinessOverview from '../pages/OurCompany/BusinessOverview.vue';
import FileUploader from '../pages/HumanResource/HRTemplates/FileUploader.vue';
import EmployeeHandbook from '../pages/HumanResource/EmployeeHandbook.vue';
import FiscalCalendar from '../pages/Calendar/FiscalCalendar.vue';
import OrganizationChart from '../pages/OrganizationChart.vue';
import Login from '../pages/Login.vue';
import EditCalendar from '../pages/Calendar/EditCalendar.vue';
import HRForms from '../pages/EmployeeForm/HRForms/HRForms.vue';
import NewHireForm from '../pages/EmployeeForm/HRForms/NewHireForm.vue';
import FinanceForms from '../pages/EmployeeForm/FinanceForms/FinanceForms.vue';
import JobDescriptionLibrary from '../pages/HumanResource/JobDescriptionLibrary/JobDescriptionLibrary.vue';
import EditJobDescriptions from '../pages/HumanResource/JobDescriptionLibrary/EditJobDescriptions.vue';
import AddJobDescriptions from '../pages/HumanResource/JobDescriptionLibrary/AddJobDescriptions.vue';
import HRProcesses from '../pages/HumanResource/Processes/Processes.vue';
import FinanceProcesses from '../pages/Finance/Processes/Processes.vue';
import Fundraising from '../pages/Finance/Processes/Fundraising.vue';
import Investment from '../pages/Finance/Processes/Investment.vue';
import FinancePolicies from '../pages/Finance/Policies/Policies.vue';
import PurchaseAndTravelRequisition from '../pages/Finance/Policies/PurchaseAndTravelRequisition.vue';
import ExpenseReimbursement from '../pages/Finance/Policies/ExpenseReimbursement.vue';
import MissionVision from '../pages/OurCompany/MissionVision.vue';
import CompanyStructure from '../pages/OurCompany/CompanyStructure.vue';
import CoreValues from '../pages/OurCompany/CoreValues.vue';
import Founder from '../pages/OurCompany/Founder.vue';
import MakeAnnouncement from '../pages/Announcement/MakeAnnouncement.vue';
import Announcement from '../pages/Announcement/Announcement.vue';
import EmployeeContacts from '../pages/OurCompany/EmployeeContacts.vue';
import AddEmployeeContacts from '../pages/OurCompany/AddEmployeeContacts.vue';
import EditEmployeeContacts from '../pages/OurCompany/EditEmployeeContacts.vue';
import AnnouncementDisplay from '../pages/Announcement/AnnouncementDisplay.vue';

import { getLocalStorageWithTimeout } from '@/utils/localStorageUtil';

const routes = [
  {
    path: '/',
    name: 'Login',
    component: Login,
  },
  {
    path: '/Dashboard',
    name: 'Dashboard',
    component: Dashboard,
  },
  {
    path: '/Introduction',
    name: 'Introduction',
    component: Introduction,
  },
  {
    path: '/BusinessOverview',
    name: 'BusinessOverview',
    component: BusinessOverview,
  },
  {
    path: '/FileUploader/:docName',
    name: 'FileUploader',
    component: FileUploader,
    props: true,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EmployeeHandbook',
    name: 'EmployeeHandbook',
    component: EmployeeHandbook,
  },
  {
    path: '/FiscalCalendar',
    name: 'FiscalCalendar',
    component: FiscalCalendar,
  },
  {
    path: '/EditCalendar',
    name: 'EditCalendar',
    component: EditCalendar,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditCalendar', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/OrganizationChart',
    name: 'OrganizationChart',
    component: OrganizationChart,
  },
  {
    path: '/HRForms',
    name: 'HRForms',
    component: HRForms,
  },
  {
    path: '/NewHireForm',
    name: 'NewHireForm',
    component: NewHireForm,
  },
  {
    path: '/FinanceForms',
    name: 'FinanceForms',
    component: FinanceForms,
  },
  {
    path: '/AddFinanceForm',
    name: 'AddFinanceForm',
    component: AddFinanceForm,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditFinanceForm', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EditFinanceForms/:id',
    name: 'EditFinanceForms',
    component: EditFinanceForms,
    props: true,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditFinanceForm', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/AddHRForm',
    name: 'AddHRForm',
    component: AddHRForm,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditHRForm', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EditHRForms/:id',
    name: 'EditHRForms',
    component: EditHRForms,
    props: true,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditHRForm', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/AddJobDescriptions',
    name: 'AddJobDescriptions',
    component: AddJobDescriptions,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isJobDescriptions', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/JobDescriptionLibrary',
    name: 'JobDescriptionLibrary',
    component: JobDescriptionLibrary,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isJobDescriptions', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EditJobDescriptions/:id',
    name: 'EditJobDescriptions',
    component: EditJobDescriptions,
    props: true,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isJobDescriptions', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/HRProcesses',
    name: 'HRProcesses',
    component: HRProcesses,
  },
  {
    path: '/FinanceProcesses',
    name: 'FinanceProcesses',
    component: FinanceProcesses,
  },
  {
    path: '/Fundraising',
    name: 'Fundraising',
    component: Fundraising,
  },
  {
    path: '/Investment',
    name: 'Investment',
    component: Investment,
  },
  {
    path: '/FinancePolicies',
    name: 'FinancePolicies',
    component: FinancePolicies,
  },
  {
    path: '/ExpenseReimbursement',
    name: 'ExpenseReimbursement',
    component: ExpenseReimbursement,
  },
  {
    path: '/PurchaseAndTravelRequisition',
    name: 'PurchaseAndTravelRequisition',
    component: PurchaseAndTravelRequisition,
  },
  {
    path: '/MissionVision',
    name: 'MissionVision',
    component: MissionVision,
  },
  {
    path: '/CompanyStructure',
    name: 'CompanyStructure',
    component: CompanyStructure,
  },
  {
    path: '/CoreValues',
    name: 'CoreValues',
    component: CoreValues,
  },
  {
    path: '/Founder',
    name: 'Founder',
    component: Founder,
  },
  {
    path: '/MakeAnnouncement',
    name: 'MakeAnnouncement',
    component: MakeAnnouncement,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isMakeAnnouncement', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/Announcement',
    name: 'Announcement',
    component: Announcement,
  },
  {
    path: '/AddEmployeeContacts',
    name: 'AddEmployeeContacts',
    component: AddEmployeeContacts,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditEmployeeContacts', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EmployeeContacts',
    name: 'EmployeeContacts',
    component: EmployeeContacts,
  },
  {
    path: '/EditEmployeeContacts',
    name: 'EditEmployeeContacts',
    component: EditEmployeeContacts,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditEmployeeContacts', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/AnnouncementDisplay/:id',
    name: 'AnnouncementDisplay',
    component: AnnouncementDisplay,
    props: true,
  },
  {
    path: '/EditPDFs',
    name: 'EditPDFs',
    component: EditPDFs,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditPDF', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/Configurations',
    name: 'Configurations',
    component: Configurations,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isEditConfig', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/ChangePassword',
    name: 'ChangePassword',
    component: ChangePassword,
  },
  {
    path: '/HRTemplates',
    name: 'HRTemplates',
    component: HRTemplates,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/InternshipOfferLetter',
    name: 'InternshipOfferLetter',
    component: InternshipOfferLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/AcceptanceofResignationLetter',
    name: 'AcceptanceofResignationLetter',
    component: AcceptanceofResignationLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/CompensationChangeLetter',
    name: 'CompensationChangeLetter',
    component: CompensationChangeLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EmployeeHandbookAcknowledgementLetter',
    name: 'EmployeeHandbookAcknowledgementLetter',
    component: EmployeeHandbookAcknowledgementLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EmployeeNovationAgreement',
    name: 'EmployeeNovationAgreement',
    component: EmployeeNovationAgreement,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EmploymentContractLetterGeneral',
    name: 'EmploymentContractLetterGeneral',
    component: EmploymentContractLetterGeneral,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EmploymentContractLetterSales',
    name: 'EmploymentContractLetterSales',
    component: EmploymentContractLetterSales,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/EmploymentContractLetterMarketing',
    name: 'EmploymentContractLetterMarketing',
    component: EmploymentContractLetterMarketing,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/MarketingFixedTerm',
    name: 'MarketingFixedTerm',
    component: MarketingFixedTerm,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/ExitInterviewForm',
    name: 'ExitInterviewForm',
    component: ExitInterviewForm,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/JobUpdateLetter',
    name: 'JobUpdateLetter',
    component: JobUpdateLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/ProbationConfirmationLetter',
    name: 'ProbationConfirmationLetter',
    component: ProbationConfirmationLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/ProbationExtensionLetter',
    name: 'ProbationExtensionLetter',
    component: ProbationExtensionLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/ProbationTerminationLetter',
    name: 'ProbationTerminationLetter',
    component: ProbationTerminationLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/ShowCauseLetter',
    name: 'ShowCauseLetter',
    component: ShowCauseLetter,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/WarningLetter-MinorMisconduct',
    name: 'WarningLetter-MinorMisconduct',
    component: WarningLetterMinorMisconduct,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/WarningLetter-MajorMisconduct',
    name: 'WarningLetter-MajorMisconduct',
    component: WarningLetterMajorMisconduct,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
  {
    path: '/WarningLetter-NonPerformance',
    name: 'WarningLetter-NonPerformance',
    component: WarningLetterNonPerformance,
    beforeEnter: (to, from, next) => {
      if (getLocalStorageWithTimeout('isHRTemplates', false)) {
        next();
      } else {
        next('/');
      }
    },
  },
];

const router = createRouter({
  history: createWebHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  const isLoginAuthenticated = getLocalStorageWithTimeout('isLoginAuthenticated', false);

  if (!isLoginAuthenticated && to.name !== 'Login') {
    next({ name: 'Login' });
  } else {
    next();
  }
});

export default router;
